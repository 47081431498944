import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import useMetaConnectFlow from 'modules/apps/apps/MetaApp/contexts/MetaAppConnectionFlowContextProvider';

const ConnectMetaStep = (props) => {
  const { onError } = props;
  const flow = useMetaConnectFlow();

  const connectMeta = async () => {
    flow
      .doLogin()
      .then(() => {
        flow.changeStep('choose_accounts');
      })
      .catch((err) => {
        onError && onError(err);
      });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectMeta}
        type='dashed'
        size='large'
        loading={flow.loading}
        icon={<NetworkIcon network='facebook' />}
      >
        {flow.loading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your Meta Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectMetaStep.propTypes = {};

export default ConnectMetaStep;
