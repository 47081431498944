export default function loadFastSpringScript(callback) {
  if (document.getElementById('fsc-api')) {
    callback?.();
    return document.getElementById('fsc-api');
  }

  const script = document.createElement('script');
  script.src = 'https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js';
  script.type = 'text/javascript';
  script.id = 'fsc-api';
  script.setAttribute(
    'data-storefront',
    'mydataninja.onfastspring.com/popup-ninja-prod-advanced-1'
  );
  script.setAttribute('data-decorate-callback', 'decorateFastspringURL');
  script.setAttribute('data-popup-webhook-received', 'fastSpringPopupCallback');
  // script.setAttribute('data-continuous', 'true');
  script.setAttribute('data-error-callback', 'errorCallback');
  // script.setAttribute('data-debug', 'true');
  script.setAttribute('data-access-key', 'N8SJIBE4TA2RR4HWJMZPEW');
  script.onload = () => {
    callback?.();
    console.log('fastspring script loaded');
  };

  document.head.appendChild(script);

  window.errorCallback = (...data) => {
    console.log('aaaaaaaa', data);
  };

  window.decorateFastspringURL = function (url) {
    let irClickID = localStorage.getItem('irClickID');
    console.log('irClickID', irClickID);
    if (irClickID) {
      let queryStringDelimiter = '?';
      if (url.indexOf('?') > -1) {
        queryStringDelimiter = '&';
      }
      return url + queryStringDelimiter + 'irClickID=' + irClickID;
    }
    return url;
  };

  return script;
}
