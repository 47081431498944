import React from 'react';
import { Steps } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import ConnectBingAdsStep from 'modules/apps/apps/BingAdsApp/components/BingAdsConnectionFlow/ConnectBingAdsStep';
import BingAdsAppDrawer from 'modules/apps/apps/BingAdsApp/drawers/BingAdsAppDrawer';
import ActivateBingAdsAccountsStep from 'modules/apps/apps/BingAdsApp/components/BingAdsConnectionFlow/ActivateBingAdsAccountsStep';
import useBingAdsConnectionFlow from 'modules/apps/apps/BingAdsApp/contexts/BingAdsAppConnectionFlowContextProvider';
import SetUpBingAdsAccountsStep from 'modules/apps/apps/BingAdsApp/components/BingAdsConnectionFlow/SetUpBingAdsAccountsStep';
import BingAdsAppConnectionCompleteStep from 'modules/apps/apps/BingAdsApp/components/BingAdsConnectionFlow/BingAdsAppConnectionCompleteStep';

const BingAdsConnectionFlow = (props) => {
  const { setUrlParams, removeParams } = useQueryParams();

  const flow = useBingAdsConnectionFlow();
  const { step } = flow;

  const { close: closeAppDrawer } = BingAdsAppDrawer.useDrawer();

  const steps = [
    {
      index: 0,
      name: 'connect',
      title: 'Connect to MyDataNinja',
      description: 'Give access to your Google Ads accounts',
      content: <ConnectBingAdsStep />,
    },
    {
      index: 1,
      name: 'choose_accounts',
      title: 'Choose Accounts',
      description: 'Which you want to work with',
      content: <ActivateBingAdsAccountsStep />,
    },
    {
      index: 2,
      name: 'set_up',
      title: 'Set Up Accounts',
      description: 'Configure tracking and other settings',
      content: <SetUpBingAdsAccountsStep />,
    },
    {
      index: 3,
      name: 'complete',
      title: 'Finish',
      description: 'Ready to Go!',
      content: <BingAdsAppConnectionCompleteStep />,
    },
  ];

  const findStep = (stepName) => {
    return steps.find(({ name }) => name === stepName);
  };

  const currentStep = findStep(step);

  return (
    <div className='flex flex-col'>
      <div className='w-full'>
        <Steps
          responsive={true}
          current={currentStep?.index || 0}
          items={steps.map(({ title, description }) => ({ title, description }))}
        />
      </div>
      <div className='w-full mt-4'>{currentStep?.content || 'Error loading content'}</div>
    </div>
  );
};

BingAdsConnectionFlow.propTypes = {};

export default BingAdsConnectionFlow;
