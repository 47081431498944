import initFacebookSdk from '@lib/facebook/initFacebookSdk';
import loginFacebookDialog from '@lib/facebook/loginFacebookDialog';
import { useState } from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { snackbar } from 'components/notifications/notifications';
import { useConnectTiktokMutation } from 'modules/apps/apps/TiktokApp/api/tiktok.api';

const useTiktokLogin = (props) => {
  const [sdkLoading, setSdkLoading] = useState(false);
  const [connectTiktokAccountsRequest, { isLoading: isConnectLoading }] =
    useConnectTiktokMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  /**
   * Start meta login process
   * @returns {Promise<Window>}
   */
  const startTiktokLoginDialog = async () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    return await connectTiktokAccountsRequest()
      .unwrap()
      .then((data) => {
        console.log('data', data);

        if (!data.url) {
          throw new Error('Error while getting redirect url');
        }

        return data.url;
      })
      .then((url) => {
        const w = window.open(url, 'Tiktok Login MyDataNinja', 'width=600,height=600');
        return w;
      });
  };

  return {
    loading: sdkLoading || isConnectLoading,
    openLogin: startTiktokLoginDialog,
    connectLoading: isConnectLoading,
  };
};

export default useTiktokLogin;
