import React, { useState } from 'react';
import { Drawer } from 'antd';

const AutomaticDrawer = (props) => {
  const { onClose, onOpen, ...rest } = props;

  const [open, setOpen] = useState(true);

  const onDrawerClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <Drawer open={open} onClose={onDrawerClose} {...rest}>
      {props.children}
    </Drawer>
  );
};

export default AutomaticDrawer;
