import { useGetFormsQuery } from 'store/redux/apis/crm.api';

export default function useCrmForms() {
  const { data: forms = [], isLoading } = useGetFormsQuery();

  return {
    forms,
    isLoading,
  };
}
