import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import TiktokConnectionFlow from 'modules/apps/apps/TiktokApp/components/TiktokConnectionFlow/TiktokConnectionFlow';
import { TiktokAppConnectionFlowContextProvider } from 'modules/apps/apps/TiktokApp/contexts/TiktokAppConnectionFlowContextProvider';

const TiktokAppDrawer = (props) => {
  const { setUrlParams } = useQueryParams();

  return (
    <AutomaticDrawer size='large' title='Connect Tiktok Account' width='80%' {...props}>
      <TiktokAppConnectionFlowContextProvider>
        <div className='flex flex-col'>
          <TiktokConnectionFlow />
        </div>
      </TiktokAppConnectionFlowContextProvider>
    </AutomaticDrawer>
  );
};

TiktokAppDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ app: 'tiktok' });
  const close = () => removeParams(['app']);

  return {
    open,
    close,
  };
};

TiktokAppDrawer.requiredParams = {
  app: (app) => 'tiktok' === app,
};

TiktokAppDrawer.closeParams = ['connection_batch', 'step'];

export default TiktokAppDrawer;
