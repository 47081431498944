import { useEffect, useState } from 'react';
import { connect, disconnect } from './socket-connection';
import useEffectOnce from 'utils/shared/useEffectOnce';
import { useDispatch, useStore } from 'react-redux';
import useAuth from 'store/redux/hooks/useAuth';
import { socketEvents } from 'utils/socket/socket-events';

export default function useSocket() {
  const [socket, setSocket] = useState(null);
  const { token, user } = useAuth();
  const dispatch = useDispatch();
  const store = useStore();

  useEffectOnce(() => {
    if (!token) return;
    connectToSocket();
    // setTimeout(() => {
    //   // console.log(window.Echo.private(`user.${user.id}`));
    //   window.Echo.private(`user.${user.id}`).on('create', (data) => {
    //     console.log('data', data);
    //     alert(JSON.stringify(data));
    //   });

    //   window.Echo.channel('public').on('create', (data) => {
    //     console.log('data', data);
    //     alert(JSON.stringify(data));
    //   });
    // }, 1000);

    return () => {
      console.log('Socket disconnect');
    };
  }, [token]);

  const connectToSocket = () => {
    const socket = connect(token);

    socket.on('connect', () => {
      socket.onAny((event, data) => {
        socketEvents.emit(event, data, dispatch, store);
      });
    });

    setSocket(socket);
  };

  return {
    socket,
  };
}
