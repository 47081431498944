import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import ValueConfiguration from '../pages/CRMDealsPage/components/ValueConfiguration';
import StatusConfiguration from '../pages/CRMDealsPage/components/StatusConfiguration/';
import useQueryParams from 'hooks/useQueryParams';

const ConfigurationListDrawer = (props) => {
  const { setUrlParams, removeParams, searchParams } = useQueryParams();
  const { tab } = searchParams;
  const [value, setValue] = useState(tab);

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  const onChange = (key) => {
    setUrlParams({ tab: key });
  };

  const items = [
    {
      key: 'value',
      label: 'Value Configuration',
      children: <ValueConfiguration />,
    },
    {
      key: 'statuses',
      label: 'Statuses',
      children: <StatusConfiguration />,
    },
  ];

  return (
    <AutomaticDrawer
      size='large'
      title='Configure Deals'
      {...props}
      onClose={() => removeParams(['flow_id', 'tab'])}
    >
      <Tabs
        className='text-md font-semibold'
        defaultActiveKey={value}
        items={items}
        onChange={onChange}
      />
    </AutomaticDrawer>
  );
};

ConfigurationListDrawer.requiredParams = {
  deal_configuration: true,
};

export default ConfigurationListDrawer;
