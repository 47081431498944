let initialized = false;

export const init = () => {
  if (initialized) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('data-website', 'WTcDaPENOQbJuPAL');
  script.setAttribute('src', 'https://static.mydataninja.com/ninja.js');
  script.async = true;
  script.defer = true;
  script.type = 'text/javascript';

  document.head.appendChild(script);
  initialized = true;

  window.nj = window.nj || [];
  window.MYDATANINJA_WEBSITE_ID = 'WTcDaPENOQbJuPAL';

  window.nj.push(['init', {}]);
  window.nj.push(['enableCrossDomain', {}]);
};

export const nj = () => {
  return window.nj;
};
