import React from 'react';
import useTiktokConnectFlow from 'modules/apps/apps/TiktokApp/contexts/TiktokAppConnectionFlowContextProvider';
import { useGetBatchAccountsQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import { Button, Table } from 'antd';
import NetworkAccountFormatter from 'components/table-formatters/network-account';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';
import AccountSetupButton from 'modules/AccountsModule/components/AccountSetupButton';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    key: 'name',
    render: (_, account) => <NetworkAccountFormatter copy={false} row={account} />,
  },
  {
    title: 'Setup',
    width: 150,
    render: (_, account) => <AccountSetupButton account={account} />,
  },
  {
    title: 'Campaigns',
    dataIndex: 'campaigns_count',
    key: 'campaigns_count',
  },
  {
    title: 'Adsets',
    dataIndex: 'adsets_count',
    key: 'adsets_count',
  },
  {
    title: 'Ads',
    dataIndex: 'ads_count',
    key: 'ads_count',
  },
  {
    title: 'Conversions',
    dataIndex: 'conversions_count',
    key: 'conversions_count',
  },
];

const SetUpTiktokAccountsStep = (props) => {
  const flow = useTiktokConnectFlow();
  const { data } = useGetBatchAccountsQuery(
    {
      batch: flow.connectionBatch,
    },
    {
      pollingInterval: 2000,
    }
  );

  const accounts = data?.data || [];

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <Table pagination={false} size='small' columns={columns} dataSource={accounts} />
      </div>
      <div className='w-full flex justify-between'>
        <Button
          type='dashed'
          icon={<Icon path={mdiArrowLeft} />}
          onClick={() => flow.changeStep('choose_accounts')}
        >
          Previous
        </Button>
        <Button
          type='primary'
          iconPosition='end'
          icon={<Icon path={mdiArrowRight} />}
          onClick={() => flow.changeStep('finish')}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

SetUpTiktokAccountsStep.propTypes = {};

export default SetUpTiktokAccountsStep;
