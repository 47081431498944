import { Select, Space } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ninjaApi from 'store/redux/apis';
import { StatusFormatter } from 'components/tables/NinjaTable/formatters';
import { LoadingOutlined } from '@ant-design/icons';
import NetworkSwitch from 'components/switches/NetworkSwitch';
import debounce from 'common/debounce';

const NinjaUrlSelect = (props) => {
  const {
    value = [],
    network = 'all',
    onChange,
    multiple = true,
    disableNetworksSelector,
    placeholder = 'Url',
    disableSelectAll = true,
    id,
    label = 'Type To Search',
    preload = 20,
    ...rest
  } = props;

  const [search, setSearch] = useState();
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [load, setLoad] = useState(preload);
  const [selectedNetwork, setSelectedNetwork] = useState(network);
  const [searchUrlsQuery, { data = [], isFetching }] = ninjaApi.useLazySearchUrlsQuery();

  const fetchData = useCallback(() => {
    searchUrlsQuery({
      network: selectedNetwork,
      search: search,
      id: id,
      preload: load,
    }).unwrap();
  }, [selectedNetwork, search, id, load]);

  useEffect(() => {
    open && fetchData();
  }, [fetchData, open]);

  const handleChange = useCallback((value) => {
    onChange?.(value);
  });

  const handleNetworksChange = useCallback((network) => {
    setSelectedNetwork(network);
  });

  const options = useMemo(() => {
    return data.filter((acc) => selectedNetwork === 'all' || acc.network === selectedNetwork);
  }, [data, selectedNetwork]);

  useEffect(() => {
    handleSearch('');
  }, [selectedNetwork]);

  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 200);

  const renderNotFoundContent = () => {
    return (
      <LoadingOutlined className='text-3xl m-auto flex items-center justify-center h-9' spin />
    );
  };

  useEffect(() => {
    if (scrollPosition > 90 && !(load > data.length)) {
      setTimeout(() => {
        setLoad(load + 50);
      }, 200);
    }
  }, [scrollPosition]);

  const handleClose = () => {
    setOpen(!open);
    setSearch('');
  };

  const onSearch = (value) => {
    handleSearch(value);
  };

  return (
    <Select
      open={open}
      mode='multiple'
      placeholder={open ? label : placeholder}
      maxTagCount='responsive'
      onChange={handleChange}
      value={value}
      allowClear
      optionFilterProp='label'
      onDropdownVisibleChange={handleClose}
      onPopupScroll={(e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
        if (scrollPercentage > 90) {
          setScrollPosition(scrollPercentage);
        }
      }}
      onSearch={onSearch}
      notFoundContent={isFetching && options.length === 0 ? renderNotFoundContent() : undefined}
      filterOption={(input, option) => {
        const isMatch = option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        return isMatch;
      }}
      dropdownRender={(menu) => (
        <>
          <NetworkSwitch
            selectedNetwork={selectedNetwork}
            handleNetworksChange={handleNetworksChange}
            disableNetworksSelector={disableNetworksSelector}
            disableSelectAll={disableSelectAll}
          />
          <div>
            {menu}
            {isFetching && options.length ? (
              <div className='w-full m-auto text-center'>
                <LoadingOutlined className='m-auto' />
              </div>
            ) : null}
          </div>
        </>
      )}
      {...rest}
    >
      {options?.map((opt, index) => {
        return (
          <Select.Option key={index} value={opt.id} label={[opt.name, opt.adset_name].join(' - ')}>
            <Space className='flex items-center justify-between w-full'>
              <div className='flex items-center justify-center w-full gap-2'>
                {opt.status && <StatusFormatter row={opt} title={false} />}
                {opt.network ? (
                  <span role='img' aria-label={opt.name}>
                    <NetworkIcon network={opt.network} size={15} />
                  </span>
                ) : null}
                <span className='font-medium text-xs'>{opt.name}</span> -
                <span className='bg-info-background rounded-full text-xs p-1'>
                  {opt.adset_name}
                </span>
              </div>
            </Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};
export default NinjaUrlSelect;
