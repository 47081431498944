import React from 'react';
import { currency_options } from '@ninja/consts';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const Currency = (props) => {
  const { value, onChange, options, ...rest } = props;

  return (
    <Select
      className='w-full !font-semibold'
      value={value}
      onChange={onChange}
      label='Currency'
      placeholder='Choose Currency'
      {...rest}
    >
      {Object.values(options || currency_options).map((currency) => (
        <Select.Option key={currency.value} value={currency.value} className='!font-semibold'>
          {currency.symbol} {currency.name}
        </Select.Option>
      ))}
    </Select>
  );
};

Currency.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
};

export default Currency;
