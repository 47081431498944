import { socketEvents } from 'utils/socket/socket-events';

const snackbar = (title = '', type = 'info', options = {}) => {
  const key = 'front.snack.' + Date.now();
  socketEvents.emit('global.snackbar', { title, type, key: key });

  /**
   *
   * @param {*} title
   * @param {*} type
   * @param {*} params
   */
  const hide = (title = title, params = {}) => {
    socketEvents.emit('global.snackbar', { key: key, title, hide: true, ...options, ...params });
  };

  const update = (title = title, params = {}) => {
    socketEvents.emit('global.snackbar', { key: key, title, ...options, ...params });
  };

  return { hide, update };
};

const notification = (title = '', message = '', type = 'info', options = {}) => {
  const key = 'front.notif.' + Date.now();
  socketEvents.emit('global.notification', { title, message, type, key: key });

  return (params = { ...options }) => {
    socketEvents.emit('global.notification', { key: key, hide: true, ...params });
  };
};

notification.success = (title, message) => notification(title, message, 'success');
notification.error = (title, message) => notification(title, message, 'error');
notification.info = (title, message) => notification(title, message, 'info');
notification.warning = (title, message) => notification(title, message, 'warning');

snackbar.success = (title) => snackbar(title, 'success');
snackbar.error = (title) => snackbar(title, 'error');
snackbar.info = (title) => snackbar(title, 'info');
snackbar.warning = (title) => snackbar(title, 'warning');
snackbar.loading = (title) => snackbar(title, 'loading');

snackbar.show = (title, status = 'loading') => {
  const { hide, update } = snackbar[status](title);

  return { hide, update };
};

/**
 * Show loading message until the promise is resolved
 *
 * @param {() => Promise} promise
 * @param {Object} config
 * @param {String} config.duration
 *
 * @param {String} config.loadingMessage
 * @param {String} config.successMessage
 * @param {String} config.errorMessage
 *
 * @param {Object} config.loadingConfig
 * @param {Object} config.successConfig
 * @param {Object} config.errorConfig
 * @returns
 */
snackbar.promise = (promise, config = {}) => {
  const { update } = snackbar.show(config.loadingMessage, 'loading', {
    ...config.loadingConfig,
  });

  return promise()
    .then((data) => {
      update(config.successMessage, {
        type: 'success',
        duration: config.duration,
        ...config.successConfig,
      });
      return data;
    })
    .catch((data) => {
      update(config.errorMessage, {
        type: 'error',
        duration: config.duration,
        ...config.errorConfig,
      });
      return data;
    });
};

snackbar.load = function (
  callback,
  options = {
    loadingMessage: 'Loading',
    successMessage: 'Success',
    errorMessage: 'Error',
    hideDuration: 2,
  }
) {
  const snack = snackbar.loading(options.loadingMessage);

  return callback()
    .then((data) => {
      snack({
        hide: false,
        title: options.successMessage,
        type: 'success',
        duration: options.hideDuration,
      });
      return data;
    })
    .catch((err) => {
      snack({
        hide: false,
        title: options.errorMessage,
        type: 'error',
        duration: options.hideDuration,
      });
      return err;
    });
};

export { notification, snackbar };
