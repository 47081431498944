import * as amp from '@amplitude/analytics-browser';

export const init = () => {
  amp.init('7e6d0f9f5b75743326bd13df0b710bae', {
    serverZone: 'EU',
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: false,
      formInteractions: true,
      fileDownloads: false,
      elementInteractions: false,
    },
  });
};

export const amplitude = () => {
  return amp;
};
