const loginFacebookDialog = () => {
  if (!window.FB) throw new Error('Facebook Not Initialized');

  return new Promise((resolve, reject) => {
    try {
      window.FB.login(
        function (response) {
          // if successfully connected
          if (response.status === 'connected') {
            response.success = true;
            resolve(response);
          }
          // if some error happened during connection
          else {
            reject("Can't connect to Meta");
          }
        },
        {
          scope:
            'ads_management,ads_read,business_management,pages_show_list,pages_read_engagement,page_events,pages_manage_ads,leads_retrieval',
        }
      );
    } catch (err) {
      reject(err);
    }
  });
};

export default loginFacebookDialog;
