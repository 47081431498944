import React, { useEffect, useState } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import useTiktokLogin from 'modules/network-accounts/hooks/useTiktokLogin';
import { useConnectTiktokMutation } from 'modules/apps/apps/TiktokApp/api/tiktok.api';
import { snackbar } from 'components/notifications/notifications';

const TiktokAppConnectionFlowContext = React.createContext();

export const TiktokAppConnectionFlowContextProvider = (props) => {
  const {
    setUrlParams,
    searchParams: { connection_batch = null, step: queryStep = 'connect' },
  } = useQueryParams();
  const [connectionBatch, _setConnectionBatch] = useState(connection_batch);
  const [connectTiktokAccountsRequest, { isLoading: isConnectLoading }] =
    useConnectTiktokMutation();
  const [step, setStep] = useState(queryStep);
  const { loading: authLoading, openLogin: openTiktokLoginPopup } = useTiktokLogin();
  const { sdkResponse, setSdkResponse } = useState(null);

  const changeStep = (newStep) => {
    setStep(newStep);
    setUrlParams({ step: newStep });
  };

  const setConnectionBatch = (batch) => {
    _setConnectionBatch(batch);
    setUrlParams({ connection_batch: batch });
  };

  /**
   * Open tiktok login dialog and connect accounts to mydataninja
   * Returns connection batch
   * @returns {String|null} connection_batch
   */
  const doLogin = async () => {
    const loginWindow = await snackbar.promise(() => openTiktokLoginPopup(), {
      loadingMessage: 'Waiting for Tiktok Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to Tiktok',
    });

    return await new Promise((res, rej) => {
      const onConnectionBatch = (batch) => {
        setConnectionBatch(batch);
        res(batch);
        loginWindow.close();
        snackbar.success('Successfully connected Tiktok accounts');
      };

      window.addEventListener('message', (event) => {
        try {
          if (event.data?.message !== 'mydataninja_tiktok_connected') {
            return;
          }

          if (!event.data.connection_batch) {
            throw new Error('Error while getting connection batch: ' + JSON.stringify(event.data));
          }

          onConnectionBatch(event.data.connection_batch);
        } catch (err) {
          console.log(err);
          snackbar.error('Error while connecting tiktok accounts, concact support');
        }
      });
    });
  };

  return (
    <TiktokAppConnectionFlowContext.Provider
      value={{
        step: step,
        connectionBatch,
        authLoading,
        sdkResponse,
        doLogin,
        changeStep,
        setConnectionBatch,
      }}
    >
      {props.children}
    </TiktokAppConnectionFlowContext.Provider>
  );
};

const useTiktokConnectFlow = () => React.useContext(TiktokAppConnectionFlowContext);

export default useTiktokConnectFlow;

/**
 * @typedef {{
 *  access_token: string,
 *  success: boolean,
 * }} TiktokJsSdkResponse
 */

/**
 * @typedef {{
 *  success: boolean,
 *  connection_batch: string
 * }} ConnectTiktokResponse
 */
