import initFacebookSdk from '@lib/facebook/initFacebookSdk';
import loginFacebookDialog from '@lib/facebook/loginFacebookDialog';
import { useState } from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { snackbar } from 'components/notifications/notifications';
import { useConnectMetaMutation } from 'modules/apps/apps/MetaApp/api/meta.api';

const useMetaLogin = (props) => {
  const [sdkLoading, setSdkLoading] = useState(false);
  const [connectMetaAccountsRequest, { isLoading: isConnectLoading }] = useConnectMetaMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  /**
   * Load meta sdk
   */
  const loadSDK = async () => {
    setSdkLoading(true);
    try {
      await initFacebookSdk();
    } catch (err) {
      snackbar.error('Error while loading META SDK');
    }
    setSdkLoading(false);
  };

  /**
   * Start meta login process
   * @returns {Promise<Object>}
   */
  const startMetaLoginDialog = async () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    await loadSDK();

    return await loginFacebookDialog();
  };

  return {
    loading: sdkLoading || isConnectLoading,
    loadSDK: loadSDK,
    openLogin: startMetaLoginDialog,
    connectLoading: isConnectLoading,
  };
};

export default useMetaLogin;
