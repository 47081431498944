import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import {
  useCheckUrlConnectionMutation,
  useGetDefaultUrlNameQuery,
} from 'modules/UrlModule/api/urls.api';
import { Form, Input, Button } from 'antd';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import * as yup from 'yup';
import AdSelect from 'components/Selects/AdSelect';
import Alert from 'components/Dialogs/Alert';

const UrlForm = (props) => {
  const { editing = false, initialData = null, onSubmit, isLoading } = props;
  const [checkUrlConnectionQuery, { data: connection = { success: true } }] =
    useCheckUrlConnectionMutation();
  const { data: default_name, isLoading: isNameLoading } = useGetDefaultUrlNameQuery(null, {
    skip: editing,
    refetchOnMountOrArgChange: true,
  });

  const handleFormSubmit = () => {
    onSubmit && onSubmit(form.values);
  };

  const form = useFormik({
    initialValues: {},
    onSubmit: handleFormSubmit,
    validationSchema: validationSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    form.setValues({
      url: initialData ? initialData.url : '',
      name: initialData ? initialData.name : 'Url #',
      ad_id: initialData ? initialData.ad_id : null,
      editable: Boolean(initialData?.editable) || false,
    });
  }, [initialData]);

  useEffect(() => {
    if (default_name && default_name.name) {
      form.setFieldValue('name', default_name.name);
    }
  }, [default_name]);

  const handleUrlChange = (e) => {
    form.setFieldValue('url', e.target.value);
    if (!e.target.value) return;
    checkUrlConnectionQuery(e.target.value);
  };

  const isUrlFilled = Boolean(form.values.url);
  const isInvalidUrl = isUrlFilled && !connection.success;

  return (
    <Form className='flex flex-col' layout='vertical'>
      <Form.Item
        label='Name'
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'name')}
      >
        <Input
          disabled={isNameLoading}
          size='large'
          placeholder='Enter Name'
          name='name'
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </Form.Item>
      <Form.Item
        label='URL'
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'url')}
      >
        <Input
          size='large'
          placeholder='Enter URL'
          name='url'
          value={form.values.url}
          onChange={handleUrlChange}
          onBlur={form.handleBlur}
        />
      </Form.Item>
      {isInvalidUrl && (
        <Alert type='warning' showIcon description='This url is not connected to your workspace' />
      )}
      <Form.Item label='Ad'>
        <AdSelect
          className='w-full h-14'
          value={form.values?.ad_id}
          mode='tag'
          preload={10}
          placeholder='Ad Name'
          onChange={(val) => {
            form.setFieldValue('ad_id', val);
          }}
        />
      </Form.Item>

      <div className='flex-center mt-4'>
        <Button size='large' type='primary' loading={isLoading} onClick={form.handleSubmit}>
          {editing ? 'Update' : 'Create'}
        </Button>
      </div>
    </Form>
  );
};

UrlForm.propTypes = {
  editing: PropTypes.bool,
  isLoading: PropTypes.bool,
  initialData: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    ad_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editable: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default UrlForm;

const validationSchema = yup.object({
  name: yup.string('Enter Name').required('Name is required'),
  url: yup.string('Enter URL').url('Please enter a valid URL').required('URL is required'),
});
