import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import SpentFormatter from 'components/table-formatters/values/SpentFormatter';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';
import AdsetTableFormatter from 'modules/adsets/table-formatters/AdsetTableFormatter';
import AdsetStatusTableFormatter from 'modules/adsets/table-formatters/AdsetStatusTableFormatter';

const list = [
  {
    key: 'status',
    name: 'Status',
    sortable: true,
    renderCell: AdsetStatusTableFormatter,
  },
  {
    name: 'Name',
    key: 'name',
    resizable: true,
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    renderCell: AdsetTableFormatter,
  },
  {
    name: 'Ads',
    key: 'ads_count',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'Spent',
    key: 'report.spent',
    sortable: true,
    renderCell: SpentFormatter,
  },
  {
    name: 'Impressions',
    key: 'report.impressions',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'Clicks',
    key: 'report.clicks',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'CPM',
    key: 'report.cpm',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'CTR',
    key: 'report.ctr',
    sortable: true,
    renderCell: ValueFormatter,
  },
];

const AdsetsTable = (props) => {
  return (
    <NinjaTable
      title='Adsets'
      dataProvider='adsetsList'
      columns={list}
      containerClassName='gap-8'
      sortableColumns
      {...props}
    />
  );
};

export default AdsetsTable;
