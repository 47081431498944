import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import { useDealSourceQuery } from 'modules/CRMModule/api/crm.api';
import DealStatuses from './DealStatuses';

const StatusConfiguration = () => {
  const { setUrlParams, searchParams } = useQueryParams();
  const { flow_id } = searchParams;
  const [value, setValue] = useState(flow_id);
  const { data: source = [], isLoading: isSourceLoading } = useDealSourceQuery();

  useEffect(() => {
    setValue(flow_id);
  }, [flow_id]);

  const onChange = (key) => {
    setUrlParams({ flow_id: key });
  };

  if (isSourceLoading) {
    return null;
  }

  if (!source?.data) {
    return null;
  }
  return (
    <Tabs
      className='mt-6 text-sm font-normal'
      defaultActiveKey={String(value)}
      onChange={onChange}
      items={
        source?.data.map((s) => ({
          label: s.name,
          key: String(s.id),
          children: <DealStatuses flow_id={s.id} />,
        })) || []
      }
    />
  );
};

export default StatusConfiguration;
