import legacy_api from 'common/api';
import api from 'store/redux/utils/api';

/**
 * Set Authorization token for API(s)
 * @param {String} token
 * @return {void}
 */
export function authenticateAPI(token) {
  legacy_api.setToken(token);
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
