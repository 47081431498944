import React, { useEffect, useState } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import { useConnectGoogleAdsMutation } from 'modules/apps/apps/GoogleAdsApp/api/google-ads.api';
import { snackbar } from 'components/notifications/notifications';
import useGoogleLogin from 'modules/network-accounts/hooks/useGoogleLogin';

const GoogleAdsAppConnectionFlowContext = React.createContext();

export const GoogleAdsAppConnectionFlowContextProvider = (props) => {
  const {
    setUrlParams,
    searchParams: { connection_batch = null, step: queryStep = 'connect' },
  } = useQueryParams();
  const [connectionBatch, _setConnectionBatch] = useState(connection_batch);
  const [connectGoogleAdsAccountsRequest, { isLoading: isConnectLoading }] =
    useConnectGoogleAdsMutation();
  const [step, setStep] = useState(queryStep);
  const { loading: authLoading, openLogin: openGoogleAdsLoginPopup } = useGoogleLogin();
  const { sdkResponse, setSdkResponse } = useState(null);

  const changeStep = (newStep) => {
    setStep(newStep);
    setUrlParams({ step: newStep });
  };

  const setConnectionBatch = (batch) => {
    _setConnectionBatch(batch);
    setUrlParams({ connection_batch: batch });
  };

  /**
   * Open google ads login dialog and connect accounts to mydataninja
   * Returns connection batch
   * @returns {String|null} connection_batch
   */
  const doLogin = async () => {
    const sdkResponse = await snackbar.promise(() => openGoogleAdsLoginPopup(), {
      loadingMessage: 'Waiting for GoogleAds Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to GoogleAds',
    });

    if (!sdkResponse.success) {
      throw new Error('Error while login to GoogleAds');
    }

    /**
     * @var {ConnectGoogleAdsResponse} sdkResponse
     */
    const apiResponse = await snackbar.promise(
      () => connectGoogleAdsAccountsRequest(sdkResponse).unwrap(),
      {
        loadingMessage: 'Connecting to MyDataNinja...',
        successMessage: 'Successfully Connected',
        errorMessage: 'Error while connecting GoogleAds to MyDataNinja',
      }
    );

    if (!apiResponse.connection_batch) {
      throw new Error('Error while connecting accounts to mydataninja');
    }

    setConnectionBatch(apiResponse.connection_batch);

    return apiResponse.connection_batch;
  };

  return (
    <GoogleAdsAppConnectionFlowContext.Provider
      value={{
        step: step,
        connectionBatch,
        authLoading,
        sdkResponse,
        doLogin,
        changeStep,
        setConnectionBatch,
      }}
    >
      {props.children}
    </GoogleAdsAppConnectionFlowContext.Provider>
  );
};

const useGoogleAdsConnectFlow = () => React.useContext(GoogleAdsAppConnectionFlowContext);

export default useGoogleAdsConnectFlow;

/**
 * @typedef {{
 *  access_token: string,
 *  success: boolean,
 * }} GoogleAdsJsSdkResponse
 */

/**
 * @typedef {{
 *  success: boolean,
 *  connection_batch: string
 * }} ConnectGoogleAdsResponse
 */
