import React, { Fragment, useState } from 'react';
import { Page } from 'components/layouts/components';
import { Alert, Card, Space, Tag, Timeline, Tooltip } from 'antd';
import UserJourneyPageFilter from 'modules/report/filters/UserJourneyPageFilter';
import {
  mdiAccountAlert,
  mdiCellphoneIphone,
  mdiClockOutline,
  mdiCursorDefaultClick,
  mdiEye,
  mdiGoogleChrome,
  mdiMicrosoftWindows,
  mdiTagPlusOutline,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { Typography } from 'antd';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import SendEventConversionButton from 'modules/report/components/SendEventConversionButton';
import ninjaApi from 'store/redux/apis';
import UserJourney from 'modules/report/components/UserJourney';

const { Text, Link } = Typography;

const UserJourneyPage = (props) => {
  const [filter, setFilter] = useState({});

  const onFilter = (values) => {
    setFilter(values);
  };

  return (
    <Page className='flex flex-col gap-4'>
      <Card>
        <UserJourneyPageFilter onSubmit={onFilter} />
      </Card>
      <UserJourney
        user_id={filter.user_id}
        event_id={filter.event_id}
        session_id={filter.session_id}
      />
    </Page>
  );
};

const TagWithTooltip = (props) => {
  const { tooltip, toolTipProps = {}, children, ...rest } = props;

  return (
    <Tooltip title={tooltip} {...toolTipProps}>
      <Tag className='flex items-center gap-2' color='default' {...rest}>
        {children}
      </Tag>
    </Tooltip>
  );
};

const EventPayload = ({ payload }) => {
  if (!payload) return null;

  const filterKeys = ['value', 'currency'];

  if (typeof payload === 'string') {
    return <Text keyboard>payload</Text>;
  }

  if (typeof payload === 'object') {
    return (
      <Space direction='vertical' size={0}>
        {Object.keys(payload)
          .filter((f) => !filterKeys.includes(f))
          .map((key) => (
            <div key={key}>
              <Text code>
                {key}: {payload[key]}
              </Text>
            </div>
          ))}
      </Space>
    );
  }

  return null;
};

UserJourneyPage.propTypes = {};

export default UserJourneyPage;
