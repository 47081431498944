import ninjaApi from 'store/redux/apis/ninja.api';
import {
  invalidatesTagsOfSingleResource,
  providesSingleResourceTag,
  provideTagsWithList,
  provideTagsWithoutList,
} from '../utils/rtk-tag-utils';

// Define a service using a base URL and expected endpoints
export const crmApi = ninjaApi.injectEndpoints({
  tagTypes: ['Form', 'CrmLead', 'Contacts', 'CromFormIntegrations', 'CrmView'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get Use Forms Query
     */
    listForms: builder.query({
      query: () => ({
        url: '/workspace/get-forms',
        method: 'POST',
      }),
      providesTags: provideTagsWithList('Form'),
    }),

    /**
     * Get single form
     */
    getForm: builder.query({
      /**
       * @param {Object} body
       * @param {Number} body.id
       */
      query: (body) => ({
        url: '/crm/form/get',
        method: 'POST',
        body,
      }),
      providesTags: providesSingleResourceTag('Form'),
    }),

    /**
     * Get Form Integrations
     */
    getFormIntegrations: builder.query({
      query: (body) => ({
        url: '/crm/form/integrations',
        method: 'POST',
        body,
      }),
      providesTags: ['CromFormIntegrations'],
    }),

    /**
     * Create new status for form
     */
    createStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/form/status/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    /**
     * Update crm form status
     */
    updateStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/form/status/edit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    /**
     * Update form field
     */
    updateFormField: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/edit',
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTagsOfSingleResource('Form', {
        id_key: 'form_id',
      })['Form'],
    }),

    /**
     * Update form field
     */
    updateFormConfig: builder.mutation({
      query: (body) => ({
        url: '/crm/form/config/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTagsOfSingleResource('Form', {
        id_key: 'form_id',
      }),
    }),

    /**
     * Delete crm form lead
     */
    deleteLead: builder.mutation({
      query: (body) => ({
        url: '/crm/form/lead/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTagsOfSingleResource('CrmLead', {
        id_key: 'lead_id',
      }),
    }),

    getCRMListData: builder.query({
      query: (data) => ({
        url: '/crm/form/lead/list',
        method: 'POST',
        data: data,
      }),
      providesTags: provideTagsWithList('CrmLead'),
    }),

    getListData: builder.query({
      query: ({ formId, page, perPage, filters = {} }) => ({
        url: '/crm/form/lead/list',
        method: 'POST',
        body: { form_id: formId, page: page, per_page: perPage, filters: filters },
      }),
      providesTags: provideTagsWithList('CrmLead'),
    }),

    /**
     * Update single lead value
     */
    updateLeadValue: builder.mutation({
      query: (body) => ({
        url: '/crm/form/lead/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CrmLead'],
    }),
    /**
     * Update lead status
     */
    updateLeadStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/form/lead/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidatesTagsOfSingleResource('CrmLead', {
        id_key: 'lead_id',
      }),
    }),
    /**
     * Delete form status
     */
    deleteStatus: builder.mutation({
      query: (body) => ({
        url: '/crm/form/status/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    /**
     * Get Use Forms Query
     */
    getForms: builder.query({
      query: () => ({
        url: '/workspace/get-forms',
        method: 'POST',
      }),
      providesTags: provideTagsWithList('Form'),
    }),

    getUsers: builder.query({
      query: (data) => ({
        url: '/crm/form/get-users',
        method: 'POST',
        data: data,
      }),
    }),

    updateForm: builder.mutation({
      query: (body) => ({
        url: '/crm/form/edit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    //publish crm form
    publishForm: builder.mutation({
      query: (body) => ({
        url: '/crm/form/publish',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    updateConversionValue: builder.mutation({
      query: (body) => ({
        url: '/crm/form/config/update-value',
        method: 'POST',
        body,
      }),
    }),

    //create option in crm
    createOptions: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/options/create',
        method: 'POST',
        body,
      }),
    }),

    //create crm form field
    createFormField: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    //delete crm form field during Configure
    deleteFormField: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/delete',
        method: 'POST',
        body,
      }),
    }),

    //delete crm field option
    deleteOption: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/options/delete',
        method: 'POST',
        body,
      }),
    }),

    //update crm field option
    updateOption: builder.mutation({
      query: (body) => ({
        url: '/crm/form/field/options/edit',
        method: 'POST',
        body,
      }),
    }),

    //crm filter
    getFilterFields: builder.query({
      query: (body) => ({
        url: '/crm/form/get-filter-fields',
        method: 'POST',
        body,
      }),
    }),

    // User History
    getLeadInfo: builder.query({
      query: (body) => ({
        url: '/crm/form/lead/info',
        method: 'POST',
        body,
      }),
    }),

    //add new comment
    addComment: builder.mutation({
      query: (body) => ({
        url: '/crm/form/lead/comment/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CrmLead'],
    }),

    //get comments
    getComments: builder.query({
      query: (body) => ({
        url: '/crm/form/lead/comment/list',
        method: 'POST',
        body,
      }),
    }),

    //get Options
    getOptions: builder.query({
      query: (body) => ({
        url: '/crm/form/field/get-options',
        method: 'POST',
        body,
      }),
    }),

    connectFacebookLeadsForm: builder.mutation({
      query: (body) => ({
        url: '/crm/form/integration/connect-facebook-leads-form',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Form'],
    }),

    saveCrmToken: builder.mutation({
      query: (body) => ({
        url: '/crm/form/integration/connect-shopify-crm',
        method: 'POST',
        body,
      }),
      invalidatesTags: [''],
    }),

    getCrmViewList: builder.query({
      query: (body) => ({
        url: '/crm/form/lead/view',
        method: 'POST',
        body,
      }),
      providesTags: ['CrmView'],
    }),

    // crm invitation
    sendCrmInvite: builder.mutation({
      query: (body) => ({
        url: '/crm/invites/send-invite',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['CrmInvite'],
    }),

    getInvitationToken: builder.query({
      query: (body) => ({
        url: '/crm/invites/get-invite',
        method: 'POST',
        body: body,
      }),
      providesTags: ['CrmInvite'],
    }),

    acceptInvite: builder.mutation({
      query: (body) => ({
        url: '/crm/invites/accept-invite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CrmInvite'],
    }),

    pendingCrmInvite: builder.query({
      query: (body) => ({
        url: '/crm/invites/pending-invites',
        method: 'POST',
        body: body,
      }),
      providesTags: ['CrmInvite'],
    }),

    getCrmUsers: builder.query({
      query: (body) => ({
        url: '/crm/invites/get-users',
        method: 'POST',
        body: body,
      }),
      providesTags: ['CrmInvite'],
    }),

    deleteInvite: builder.mutation({
      query: (body) => ({
        url: '/crm/invites/remove-invite',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['CrmInvite'],
    }),
  }),
});

// Export hooks for usage in functional components, which are
export const {
  useListFormsQuery,
  useGetFormsQuery,
  useGetUsersQuery,
  useUpdateFormMutation,
  useUpdateFormConfigMutation,

  useGetFormQuery,
  useGetFormIntegrationsQuery,
  useGetCRMListDataQuery,
  usePublishFormMutation,
  useUpdateConversionValueMutation,
  useCreateOptionsMutation,
  useCreateFormFieldMutation,
  useDeleteFormFieldMutation,
  useUpdateFormFieldMutation,
  useCreateStatusMutation,
  useDeleteStatusMutation,
  useUpdateStatusMutation,
  useDeleteOptionMutation,
  useUpdateOptionMutation,
  useGetFilterFieldsQuery,
  useGetLeadInfoQuery,
  useGetListDataQuery,
  useUpdateLeadValueMutation,
  useUpdateLeadStatusMutation,
  useDeleteLeadMutation,
  useGetCommentsQuery,
  useGetOptionsQuery,
  useAddCommentMutation,
  useSaveCrmTokenMutation,
  useConnectFacebookLeadsFormMutation,
  useGetContactsQuery,
  useAddContactsCommentMutation,
  useGetContactsCommentsQuery,
  // crm view
  useGetCrmViewListQuery,

  useSendCrmInviteMutation,
  useGetInvitationTokenQuery,
  useAcceptInviteMutation,
  usePendingCrmInviteQuery,
  useGetCrmUsersQuery,
  useDeleteInviteMutation,
} = crmApi;
