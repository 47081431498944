import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import { Typography } from '@material-ui/core';
import { NetworkFormatter, StatusFormatter } from 'components/tables/NinjaTable/formatters';
import ConversionFormatter from 'components/table-formatters/conversions/ConversionFormatter';

const list = [
  {
    name: 'id',
    key: 'id',
    renderSummaryCell: ({ row }) => {
      return <strong className='text-xs'>{row.count}</strong>;
    },
  },
  {
    name: 'Name',
    key: 'name',
    resizable: true,
    maxWidth: 250,
    minWidth: 250,
    renderCell: ConversionFormatter,
  },
  {
    name: 'Type',
    key: 'source',
    minWidth: 250,
    resizable: true,
    renderCell: ({ row }) => {
      return <div className='font-normal flex-nowrap truncate text-ellipsis'>{row.source}</div>;
    },
  },
  {
    name: 'Total Received',
    key: 'total_received',
    sortable: true,
    renderCell: ({ row }) => {
      return <div className='font-semibold text-sm'>{row.total_received}</div>;
    },
  },
  {
    name: 'Total Value',
    key: 'total_value',
    sortable: true,
    renderCell: ({ row }) => {
      return <div className='font-semibold text-sm'>{row.total_value}</div>;
    },
  },
];

const NetworkConversionsTable = (props) => {
  return (
    <NinjaTable
      title='Conversions'
      dataProvider='conversionsList'
      columns={list}
      containerClassName='gap-8'
      sortableColumns
      {...props}
    />
  );
};

export default NetworkConversionsTable;
