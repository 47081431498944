import React, { useEffect, useState, useCallback } from 'react';
import { Select, Spin } from 'antd';
import ninjaApi from 'store/redux/apis';
import debounce from 'common/debounce';

const CrmContactSelector = (props) => {
  const { onChange, value = [] } = props;
  const [getGetContactsQuery, { data = [], isFetching }] = ninjaApi.useLazyGetContactsQuery();
  const [open, setOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [load, setLoad] = useState(50);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [search, setSearch] = useState();

  const fetchContacts = useCallback(() => {
    getGetContactsQuery({
      contact_type: 'P',
      per_page: load,
      query: search,
    })
      .unwrap()
      .then((res) => {
        setContacts(Array.isArray(res.data) ? res.data : []);
      });
  }, [getGetContactsQuery, search, load]);

  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
    setLoad(50);
  }, 200);

  useEffect(() => {
    if (open) {
      fetchContacts();
    }
  }, [fetchContacts, open]);

  useEffect(() => {
    if (scrollPosition > 90 && !(load > (data.pagination?.total || 0))) {
      setLoad((prevLoad) => prevLoad + 50);
    }
  }, [scrollPosition, load, data.pagination]);

  const handleDropdownVisibleChange = (visible) => {
    setOpen(visible);
    if (!visible) setSearch();
  };

  const handleChange = useCallback(
    (value) => {
      onChange?.(value);
    },
    [onChange]
  );

  const handlePopupScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
    if (scrollPercentage > 90) {
      setScrollPosition(scrollPercentage);
    }
  }, []);

  return (
    <Select
      showSearch
      allowClear
      open={open}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      placeholder='Search and select a contact'
      filterOption={false}
      optionLabelProp='label'
      value={value}
      onChange={handleChange}
      onPopupScroll={handlePopupScroll}
      onSearch={handleSearch}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {isFetching && contacts.length ? (
            <div className='w-full m-auto text-center'>
              <Spin size='small m-auto' />
            </div>
          ) : null}
        </div>
      )}
      notFoundContent={
        isFetching && contacts.length === 0 ? (
          <Spin size='small' className='w-full m-auto text-center' />
        ) : undefined
      }
      {...props}
    >
      {contacts?.map((opt) => (
        <Select.Option key={opt.id} value={opt.id} label={opt.email}>
          <div className='flex items-center w-full gap-2'>
            <span className='font-medium text-xs'>{opt.first_name}</span>
            <span className='font-medium text-xs'>{opt.last_name}</span>
            <span className='font-medium text-xs'>- {opt.email}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CrmContactSelector;
