import React from 'react';
import { Select } from 'antd';

const GenderSelect = (props) => {
  return (
    <Select
      className='w-full'
      {...props}
      showSearch
      placeholder='Select gender'
      optionFilterProp='label'
      options={[
        {
          value: 'male',
          label: 'Male',
        },
        {
          value: 'female',
          label: 'Female',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ]}
    />
  );
};

export default GenderSelect;
