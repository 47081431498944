import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import useGoogleAdsConnectFlow from 'modules/apps/apps/GoogleAdsApp/contexts/GoogleAdsAppConnectionFlowContextProvider';

const ConnectGoogleAdsStep = (props) => {
  const { onError } = props;
  const flow = useGoogleAdsConnectFlow();

  const connectGoogleAds = async () => {
    flow
      .doLogin()
      .then(() => {
        flow.changeStep('choose_accounts');
      })
      .catch((err) => {
        onError && onError(err);
      });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectGoogleAds}
        type='dashed'
        size='large'
        loading={flow.loading}
        icon={<NetworkIcon network='google' />}
      >
        {flow.loading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your GoogleAds Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectGoogleAdsStep.propTypes = {};

export default ConnectGoogleAdsStep;
