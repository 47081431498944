import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import UserJourney from 'modules/report/components/UserJourney';
import React, { useState } from 'react';

const UserJourneyDrawer = (props) => {
  const { searchParams, removeParams } = useQueryParams();

  return (
    <AutomaticDrawer size='large' title='Detailed Journey View' {...props}>
      <UserJourney
        user_id={searchParams.user_id}
        event_id={searchParams.event_id}
        session_id={searchParams.session_id}
      />
    </AutomaticDrawer>
  );
};

UserJourneyDrawer.requiredParams = {
  report: (report) => 'user_journey' === report,
  user_id: () => true,
  event_id: () => true,
  session_id: () => true,
};

UserJourneyDrawer.useDrawer = (accountID) => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = (data = {}) => {
    setUrlParams({ report: 'user_journey', ...data });
  };

  const close = () => removeParams(['report', 'user_id', 'event_id', 'session_id']);

  return {
    open,
    close,
  };
};

export default UserJourneyDrawer;
