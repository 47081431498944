import { Button, Result } from 'antd';
import useMetaConnectFlow from 'modules/apps/apps/MetaApp/contexts/MetaAppConnectionFlowContextProvider';
import MetaAppDrawer from 'modules/apps/apps/MetaApp/drawers/MetaAppDrawer';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';
import { useGetAccountsByBatchQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const MetaAppConnectionCompleteStep = (props) => {
  const flow = useMetaConnectFlow();
  const { data } = useGetAccountsByBatchQuery({
    batch: flow.connectionBatch,
  });

  const { close, open } = MetaAppDrawer.useDrawer();

  const history = useHistory();

  const accounts = data?.data || [];

  return (
    <Result
      status='success'
      title='Successfully Connected Meta accounts'
      subTitle={`You have successfully connected ${accounts.length} accounts. It may take couple minutes to sync all data`}
      extra={[
        <Button
          type='primary'
          onClick={() => {
            close();
            history.push(P.DASHBOARD.INDEX);
          }}
          key='dashboard'
        >
          Go To Dashboard
        </Button>,
        <Button
          key='connect'
          onClick={() => {
            close();
            setTimeout(() => {
              open();
            }, 300);
          }}
        >
          Connect More Accounts
        </Button>,
      ]}
    />
  );
};

MetaAppConnectionCompleteStep.propTypes = {};

export default MetaAppConnectionCompleteStep;
