import React from 'react';
import { Button, Result, Steps } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import ConnectGoogleAdsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/ConnectGoogleAdsStep';
import GoogleAdsAppDrawer from 'modules/apps/apps/GoogleAdsApp/drawers/GoogleAdsAppDrawer';
import ActivateGoogleAdsAccountsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/ActivateGoogleAdsAccountsStep';
import useGoogleAdsConnectionFlow from 'modules/apps/apps/GoogleAdsApp/contexts/GoogleAdsAppConnectionFlowContextProvider';
import SetUpGoogleAdsAccountsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/SetUpGoogleAdsAccountsStep';
import GoogleConnectionCompleteStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/GoogleConnectionCompleteStep';

const GoogleAdsConnectionFlow = (props) => {
  const flow = useGoogleAdsConnectionFlow();
  const { step } = flow;

  const steps = [
    {
      index: 0,
      name: 'connect',
      title: 'Connect to MyDataNinja',
      description: 'Give access to your Google Ads accounts',
      content: <ConnectGoogleAdsStep />,
    },
    {
      index: 1,
      name: 'choose_accounts',
      title: 'Choose Accounts',
      description: 'Which you want to work with',
      content: <ActivateGoogleAdsAccountsStep />,
    },
    {
      index: 2,
      name: 'set_up',
      title: 'Set Up Accounts',
      description: 'Configure tracking and other settings',
      content: <SetUpGoogleAdsAccountsStep />,
    },
    {
      index: 3,
      name: 'complete',
      title: 'Finish',
      description: 'Ready to Go!',
      content: <GoogleConnectionCompleteStep />,
    },
  ];

  const findStep = (stepName) => {
    return steps.find(({ name }) => name === stepName);
  };

  const currentStep = findStep(step);

  return (
    <div className='flex flex-col'>
      <div className='w-full'>
        <Steps
          responsive={true}
          current={currentStep?.index || 0}
          items={steps.map(({ title, description }) => ({ title, description }))}
        />
      </div>
      <div className='w-full mt-4'>{currentStep?.content || 'Error loading content'}</div>
    </div>
  );
};

GoogleAdsConnectionFlow.propTypes = {};

export default GoogleAdsConnectionFlow;
