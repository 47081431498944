import React from 'react';
import { Steps } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import ConnectMetaStep from 'modules/apps/apps/MetaApp/components/MetaConnectionFlow/ConnectMetaStep';
import MetaAppDrawer from 'modules/apps/apps/MetaApp/drawers/MetaAppDrawer';
import ActivateMetaAccountsStep from 'modules/apps/apps/MetaApp/components/MetaConnectionFlow/ActivateMetaAccountsStep';
import useMetaConnectFlow from 'modules/apps/apps/MetaApp/contexts/MetaAppConnectionFlowContextProvider';
import SetUpMetaAccountsStep from 'modules/apps/apps/MetaApp/components/MetaConnectionFlow/SetUpMetaAccountsStep';
import MetaAppConnectionCompleteStep from 'modules/apps/apps/MetaApp/components/MetaConnectionFlow/MetaAppConnectionCompleteStep';

const MetaConnectionFlow = (props) => {
  const { setUrlParams, removeParams } = useQueryParams();

  const flow = useMetaConnectFlow();
  const { step } = flow;

  const { close: closeAppDrawer } = MetaAppDrawer.useDrawer();

  const steps = [
    {
      index: 0,
      name: 'connect',
      title: 'Connect to MyDataNinja',
      description: 'Give access to your meta ad accounts',
      content: <ConnectMetaStep />,
    },
    {
      index: 1,
      name: 'choose_accounts',
      title: 'Choose Accounts',
      description: 'Which you want to work with',
      content: <ActivateMetaAccountsStep />,
    },
    {
      index: 2,
      name: 'set_up',
      title: 'Set Up Accounts',
      description: 'Configure tracking and other settings',
      content: <SetUpMetaAccountsStep />,
    },
    {
      index: 3,
      name: 'complete',
      title: 'Finish',
      description: 'Ready to Go!',
      content: <MetaAppConnectionCompleteStep />,
    },
  ];

  const findStep = (stepName) => {
    return steps.find(({ name }) => name === stepName);
  };

  const currentStep = findStep(step);

  return (
    <div className='flex flex-col'>
      <div className='w-full'>
        <Steps
          responsive={true}
          current={currentStep?.index || 0}
          items={steps.map(({ title, description }) => ({ title, description }))}
        />
      </div>
      <div className='w-full mt-4'>{currentStep?.content || 'Error loading content'}</div>
    </div>
  );
};

MetaConnectionFlow.propTypes = {};

export default MetaConnectionFlow;
