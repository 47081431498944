import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import useBingAdsConnectFlow from 'modules/apps/apps/BingAdsApp/contexts/BingAdsAppConnectionFlowContextProvider';

const ConnectBingAdsStep = (props) => {
  const { onError } = props;
  const flow = useBingAdsConnectFlow();

  const connectBingAds = async () => {
    flow
      .doLogin()
      .then(() => {
        flow.changeStep('choose_accounts');
      })
      .catch((err) => {
        onError && onError(err);
      });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectBingAds}
        type='dashed'
        size='large'
        loading={flow.loading}
        icon={<NetworkIcon network='bing' />}
      >
        {flow.loading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your BingAds Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectBingAdsStep.propTypes = {};

export default ConnectBingAdsStep;
