import React, { useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  useSetupAccountMutation,
  useSkipAccountSetupMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';
import { notification, snackbar } from 'components/notifications/notifications';
import Icon from '@mdi/react';
import { mdiAlert, mdiCheckCircle, mdiCloseCircle, mdiProgressClock } from '@mdi/js';
import Ninja from '@ninja';
import MetaAccountSetup from 'modules/apps/apps/MetaApp/components/MetaAccountSetup';
import GoogleAdsAccountSetup from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsAccountSetup';
import TiktokAccountSetup from 'modules/apps/apps/TiktokApp/components/TiktokAccountSetup';

const AccountSetupButton = ({ account }) => {
  const [setupAccountQuery, { isLoading }] = useSetupAccountMutation();
  const [skipAccountSetupQuery, { isLoading: isSkipLoading }] = useSkipAccountSetupMutation();
  const [setup, setSetup] = useState({});
  const setupStatus = account?.data?.setup_status;

  const setupAccount = () => {
    setupAccountQuery({
      batch: account.batch,
      account_id: account.id,
      setup: setup,
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        notification.success(
          `Setup successfully requested`,
          `"${account.name}" setup requested. It may take a couple minutes. You will be notified once setup is completed`
        );
      })
      .catch((err) => {
        console.log(err);
        snackbar.error('Error while account setup request');
      });
  };

  const skipSetup = () => {
    skipAccountSetupQuery({
      batch: account.batch,
      account_id: account.id,
    }).unwrap();
  };

  const SetupDescription = setupsByNetwork[account.network];

  const setupConfigs = {
    requested: {
      icon: <Icon path={mdiProgressClock} color={Ninja.colors.warning} />,
      tooltip: 'Set up already requested, you will be notified when it is completed',
      disabled: true,
      buttonText: 'Set Up Requested',
    },
    completed: {
      icon: <Icon path={mdiCheckCircle} color={Ninja.colors.green} />,
      tooltip: 'Set up already completed',
      disabled: true,
      buttonText: '',
    },
    error: {
      icon: <Icon path={mdiCloseCircle} color={Ninja.colors.red} />,
      tooltip: 'Error while setup, please contact support',
      disabled: true,
      buttonText: 'Set up error',
    },
    skipped: {
      icon: <Icon path={mdiAlert} color={Ninja.colors.warning} />,
      tooltip: 'Skipped setup',
      disabled: false,
      buttonText: 'Skipped Setup',
    },
    none: {
      icon: <Icon path={mdiCloseCircle} color={Ninja.colors.red} />,
      tooltip: 'Set up account',
      disabled: false,
      buttonText: 'Set Up',
    },
  };

  const setupConfig = setupConfigs[setupStatus ?? 'none'] ?? null;

  if (!setupConfig) {
    return 'setup error';
  }

  return (
    <Popconfirm
      placement='top'
      title='MyDataNinja Setup'
      description={<SetupDescription onChange={setSetup} /> ?? 'Setup'}
      rootClassName='max-w-[400px]'
      okText='Set Up'
      cancelText='Skip For Now'
      okButtonProps={{ loading: isLoading, size: 'middle' }}
      cancelButtonProps={{
        icon: <Icon path={mdiAlert} color={Ninja.colors.warning} />,
        size: 'middle',
        loading: isSkipLoading,
      }}
      onConfirm={() => setupAccount(false)}
      onCancel={() => skipSetup()}
    >
      <Tooltip title={setupConfig.tooltip}>
        <Button
          size='medium'
          icon={setupConfig.icon}
          loading={isLoading || isSkipLoading}
          disabled={setupConfig.disabled}
        >
          {setupConfig.buttonText}
        </Button>
      </Tooltip>
    </Popconfirm>
  );
};

const setupsByNetwork = {
  facebook: MetaAccountSetup,
  google: GoogleAdsAccountSetup,
  tiktok: TiktokAccountSetup,
  bing: TiktokAccountSetup,
};

AccountSetupButton.propTypes = {};

export default AccountSetupButton;
