import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import {
  useAcceptInviteMutation,
  useLazyGetInvitationByTokenQuery,
} from 'store/redux/apis/workspace/permissions.api';
import url from '@ninja/url';
import { Typography } from 'antd';
import UrlHelper from 'common/utils/url-helper';

const BookDemoPage = () => {
  const invite_token = url.get('invite_token');
  const history = useHistory();
  const [getInvitation, { data: invitation, isLoading: isInvitationLoading }] =
    useLazyGetInvitationByTokenQuery();
  const [acceptInvite, { isLoading: isAcceptLoading }] = useAcceptInviteMutation();

  const acceptInvitation = () => {
    acceptInvite({
      hash: invite_token,
    })
      .unwrap()
      .then((response) => {
        redirectToDashboard();
      })
      .catch((err) => {
        if (err.status == 401) {
          history.push(
            P.AUTH.REGISTER + `?email=${btoa(invitation.email)}&invite_token=${invite_token}`
          );
        }
      });
  };

  useEffect(() => {
    getInvitation({
      hash: invite_token,
    })
      .unwrap()
      .then((d) => {
        console.log(d);
      });
  }, [invite_token]);

  const redirectToLogin = () => {
    history.push(P.AUTH.LOGIN);
  };

  const redirectToDashboard = () => {
    history.push(P.DASHBOARD.INDEX);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    script.setAttribute('id', 'calendly-script');
    script.async = true;

    document.head.appendChild(script);
  }, []);

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <Typography.Title level={1} className='!text-white'>
            MyDataNinja
          </Typography.Title>
        </a>
        <div
          className='calendly-inline-widget'
          data-url='https://calendly.com/alfonso-mydataninja/demo-mydataninja?hide_gdpr_banner=1'
          style={{ minWidth: 320, height: 700, width: '100%' }}
        ></div>
      </div>
    </div>
  );
};

export default BookDemoPage;
