import React from 'react';
import NinjaTable from 'components/tables/NinjaTable';
import CampaignTableFormatter from 'modules/campaigns/table-formatters/CampaignTableFormatter';
import CampaignStatusTableFormatter from 'modules/campaigns/table-formatters/CampaignStatusTableFormatter';
import SpentFormatter from 'components/table-formatters/values/SpentFormatter';
import ValueFormatter from 'components/table-formatters/values/ValueFormatter';

const list = [
  {
    key: 'status',
    name: 'Status',
    sortable: true,
    renderCell: CampaignStatusTableFormatter,
  },
  {
    name: 'Name',
    key: 'name',
    resizable: true,
    sortable: true,
    maxWidth: 250,
    minWidth: 250,
    renderCell: CampaignTableFormatter,
  },
  {
    name: 'Adsets',
    key: 'adsets_count',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'Spent',
    key: 'report.spent',
    sortable: true,
    renderCell: SpentFormatter,
  },
  {
    name: 'Impressions',
    key: 'report.impressions',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'Clicks',
    key: 'report.clicks',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'CPM',
    key: 'report.cpm',
    sortable: true,
    renderCell: ValueFormatter,
  },
  {
    name: 'CTR',
    key: 'report.ctr',
    sortable: true,
    renderCell: ValueFormatter,
  },
];

const CampaignsTable = (props) => {
  return (
    <NinjaTable
      title='Campaigns'
      dataProvider='campaignsList'
      columns={list}
      containerClassName='gap-8'
      sortableColumns
      {...props}
    />
  );
};

export default CampaignsTable;
