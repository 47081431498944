import NetworkAccountMainMenu from 'modules/network-accounts/menus/NetworkAccountMainMenu';
import CustomUrlMainMenu from 'modules/UrlModule/menus/CustomUrlMainMenu';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PostbacksMainMenu from 'modules/PostbacksModule/menus/PostbacksMainMenu';
import ReportMainMenu from 'modules/report/menus/ReportMainMenu';
import SalesSecondaryMenu from 'modules/sales/menus/SalesSecondaryMenu';
import ReportingMainMenu from 'modules/ReportingModule/menus/ReportingMainMenu';
import CrmMainMenu from 'modules/CRMModule/menus/CrmMainMenu';

const SecondaryMenu = (props) => {
  return (
    <Switch>
      <Route path='/accounts' component={NetworkAccountMainMenu} />
      <Route path='/report' component={ReportMainMenu} />
      <Route path='/postbacks' component={PostbacksMainMenu} />
      <Route path='/url' component={CustomUrlMainMenu} />
      <Route path='/sales' component={SalesSecondaryMenu} />
      <Route path='/reporting' component={ReportingMainMenu} exact={true} />
      <Route path='/reporting/creative' component={ReportingMainMenu} exact={true} />
      <Route path='/crm' component={CrmMainMenu} />
    </Switch>
  );
};

export default SecondaryMenu;
