import React from 'react';
import { Input, Form, Space } from 'antd';
import CountriesSelect from 'components/Selects/CountriesSelect';

const PhoneNumberInput = ({
  onCountryChange,
  onPhoneNumberChange,
  phoneNumber,
  country,
  countryProps,
  phoneNumberProps,
  formItemProps,
}) => {
  return (
    <Space.Compact style={{ width: '100%' }}>
      <Form.Item hasFeedback style={{ width: '40%' }} {...formItemProps}>
        <CountriesSelect
          style={{ maxWidth: '100%' }}
          value={country}
          onChange={onCountryChange}
          size='large'
          popupMatchSelectWidth={false}
          autocomplete='one-time-code'
          showPhoneCode
          {...countryProps}
        />
      </Form.Item>
      <Form.Item className='w-full' hasFeedback>
        <Input
          className='w-full'
          type='number'
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          size='large'
          placeholder='Phone Number'
          name='phone_number'
          {...phoneNumberProps}
        />
      </Form.Item>
    </Space.Compact>
  );
};

export default PhoneNumberInput;
