import {
  useCreateFastSpringSubscriptionMutation,
  useGetFastSpringPayloadMutation,
  useUpdateFastSpringSubscriptionMutation,
} from 'store/redux/states/billing/billing.api';
import useFastSpringScript from '@lib/fastspring/useFastSpringScript';
import useAuth from 'store/redux/hooks/useAuth';
import { ninjaEvents } from 'tracking/ninjaEvents';
import useWorkspace from 'store/redux/hooks/useWorkspace';

function useFastspringButton(props) {
  const { workspace } = useWorkspace();

  const [subscribeFastSpringQuery, { isLoading: subscriptionLoading }] =
    useCreateFastSpringSubscriptionMutation();
  const [updateFastSpringSubscriptionQuery, { isLoading: isUpdateLoading }] =
    useUpdateFastSpringSubscriptionMutation();
  const [getFastSpringPayloadQuery, { isLoading: isPayloadLoading }] =
    useGetFastSpringPayloadMutation();

  const { user } = useAuth();

  function fastSpringPopupCallback(data) {
    console.log('fastspring data', data);
    // eslint-disable-next-line no-undef
    ninjaEvents.emit('subscription_purchase_started', workspace, props.planCode);

    subscribeFastSpringQuery({
      data: data,
    })
      .then((res) => {
        ninjaEvents.emit('subscription_purchased', workspace, props.planCode);
        props.onSuccess?.(res);
      })
      .catch((err) => {
        props.onError?.(err);
      });
  }

  const { loading: scriptLoading } = useFastSpringScript({ callback: fastSpringPopupCallback });

  function checkout(planCode) {
    /** If plan update change is requested */
    if (props.update) {
      return updateFastSpringSubscriptionQuery({
        code: planCode,
      })
        .unwrap()
        .then((data) => {
          return data;
        })
        .catch((err) => {
          props?.onError(err);
        });
    }

    try {
      getFastSpringPayloadQuery({
        code: planCode,
      })
        .unwrap()
        .then((data) => {
          window.fastspring.builder.clean();
          window.fastspring.builder.secure(data.securePayload, data.secureKey);
          window.fastspring.builder.checkout();
        });
    } catch (error) {
      props?.onError?.(error);
    }
  }

  return {
    checkout,
    isScriptLoading: scriptLoading,
    isSubscriptionLoading: subscriptionLoading || isUpdateLoading || isPayloadLoading,
  };
}

export default useFastspringButton;
