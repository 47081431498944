import { CircularProgress, InputAdornment } from '@material-ui/core';
import { mdiInformation } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from 'antd';
import { ActionButton, NetworkIndicators, SyncButton } from 'components/form/field-helpers';
import React from 'react';

const adornmentGenerator = (conf, position, props = {}) => {
  const nativeAdornmentKey = position + 'Adornment';
  const { InputProps = {}, color = 'secondary' } = props;

  // Check whether to show adornment
  if (!conf && typeof InputProps[nativeAdornmentKey] === 'undefined') return null;

  let actions,
    loading,
    icon,
    info,
    help,
    networks,
    syncer = null;

  /* Generate sync adornment */
  syncer = typeof conf?.sync === 'object' ? <SyncButton config={conf.sync} /> : null;

  /* Generate icon adornment */
  icon = conf?.icon ? <Icon path={conf.icon} /> : null;

  // /* Generate info adornment */
  // info = conf?.info ? null : null;

  /* Generate help adornment */
  help = conf?.help ? (
    <Tooltip title={conf.help}>
      <Icon path={mdiInformation} />
    </Tooltip>
  ) : null;

  /* Generate loading adornment */
  loading = conf?.loading ? <CircularProgress color={color} size={15} /> : null;

  /* Generate networks adornment */
  networks = conf?.networks ? <NetworkIndicators indicators={conf.networks} /> : null;

  /* Generate action adornments */
  actions = conf?.actions
    ? Object.values(conf.actions).map((action, ind) => {
        if (!action) return null;
        return <ActionButton key={ind} {...action} />;
      })
    : null;

  return (
    <InputAdornment className='flex gap-1'>
      {icon}
      {actions}
      {syncer}
      {info}
      {networks}
      {help}
      {loading}
      {InputProps[nativeAdornmentKey]}
    </InputAdornment>
  );
};

export default adornmentGenerator;
