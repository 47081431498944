import React, { useState } from 'react';
import { Button, Card, Popover, Badge } from 'antd';
import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';

const NinjaTableFilter = ({ filterComponent }) => {
  const { filters } = useNinjaTable();
  const [open, setOpen] = useState(false);
  const filterCount = (filters) => {
    let count = 0;

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value !== null && value !== undefined) {
        if (key === 'date_from' || key === 'date_to') {
          count = 1;
        } else if (key === 'value' && typeof value === 'object') {
          const nestedCount = Object.entries(value).filter(
            ([nestedKey, nestedValue]) =>
              nestedKey !== 'type' &&
              nestedValue !== null &&
              nestedValue !== undefined &&
              nestedValue !== ''
          ).length;
          if (nestedCount > 0) {
            count++;
          }
        } else if (value !== '') {
          count++;
        }
      }
    });

    return count;
  };

  return (
    <Popover
      placement='bottomLeft'
      trigger='click'
      open={open}
      onOpenChange={() => setOpen(!open)}
      content={
        <Card size='small' className='w-[400px]'>
          {filterComponent}
        </Card>
      }
    >
      <Badge count={filterCount(filters)} size='small'>
        <Button size='large' onClick={() => setOpen(!open)} icon={<Icon path={mdiFilter} />} />
      </Badge>
    </Popover>
  );
};

export default NinjaTableFilter;
