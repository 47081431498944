import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input, Button, Form, Typography, Alert } from 'antd';
import { useLoginMutation } from 'store/redux/apis/user.api';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import gtagevent from '@lib/google-analytics/gtagevent';
import UrlHelper from 'common/utils/url-helper';
import { ninjaEvents } from 'tracking/ninjaEvents';

const LoginPage = () => {
  const [loginQuery, { isLoading: isLoginLoading, isError }] = useLoginMutation();
  const { bootstrapWithToken } = useBootstrapUser();

  const loginUser = (data) => {
    loginQuery(data)
      .unwrap()
      .then((data) => {
        if (!data.token) return;
        ninjaEvents.emit('login', data);
        // eslint-disable-next-line no-undef
        bootstrapWithToken(data.token);
      });
  };

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: loginUser,
    validateOnChange: false,
  });

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />

          <Typography.Title level={1} className='!text-white'>
            MyDataNinja
          </Typography.Title>
        </a>

        <div className='bg-white p-5 rounded-md shadow-md transition-all duration-300 w-full md:w-1/3 lg:w-1/5'>
          <div>
            <p className='font-bold text-xl text-color-dark-text mb-5'>Log In To Your Account</p>
          </div>
          <form className='disable-antd-margin' onSubmit={form.handleSubmit}>
            <div className='grid grid-cols-2 flex-wrap gap-4'>
              {isError ? (
                <div className='col-span-2'>
                  <Alert
                    closable
                    className='w-full'
                    type='error'
                    message='Invalid email or password                                        '
                  />
                </div>
              ) : null}
              <div className='col-span-2'>
                <Form.Item
                  hasFeedback
                  {...formikFieldToAntProps(form.errors, form.touched, 'email')}
                >
                  <Input
                    size='large'
                    type='email'
                    placeholder='Email'
                    name='email'
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Item>
              </div>
              <div className='col-span-2 '>
                <Form.Item
                  hasFeedback
                  {...formikFieldToAntProps(form.errors, form.touched, 'password')}
                >
                  <Input.Password
                    size='large'
                    type='password'
                    placeholder='Password'
                    name='password'
                    value={form.values.password}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Item>
              </div>
              <Link className='link-not-underlined text-xs ml-1' to={P.AUTH.RESET_PASSWORD}>
                Forgot Password?
              </Link>
            </div>
            <div className='flex-center pt-4'>
              <Button loading={isLoginLoading} htmlType='submit' size='large' type='primary'>
                Log In
              </Button>
            </div>
          </form>
        </div>
        <div className='pt-4'>
          <Typography.Text className='text-white text-sm font-bold'>
            Don't Have Account?{' '}
            <Link to={P.AUTH.REGISTER} className='text-white text-sm link'>
              Sign Up
            </Link>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  password: yup.string('Enter your password').required('Password is required'),
});
