import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDeleteStatusMutation, useUpdateStatusMutation } from 'modules/CRMModule/api/crm.api';
import ninjaApi from 'store/redux/apis';
import { Button, Flex, ColorPicker, List, Skeleton, Typography, Divider } from 'antd';
import { mdiPlus, mdiDrag, mdiPencil, mdiTrashCanOutline, mdiContentSave, mdiClose } from '@mdi/js';
import Ninja from '@ninja';
import CreateStatus from './CreateStatus';

const DealStatuses = ({ flow_id }) => {
  const [deleteStatus, { isLoading: deleteLoading }] = useDeleteStatusMutation();
  const [updateStatus, { isLoading: updateLoading }] = useUpdateStatusMutation();
  const [getDealStatusListQuery, { data = [], isLoading }] = ninjaApi.useLazyDealStatusListQuery();

  const [addStatus, setAddStatus] = useState(false);
  const [colorHex, setColorHex] = useState('#000000');
  const [formatHex, setFormatHex] = useState('hex');
  const [editingStatusId, setEditingStatusId] = useState(null);
  const [tempStatus, setTempStatus] = useState('');

  const hexString = React.useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );

  useEffect(() => {
    getDealStatusListQuery({ deal_status_flow_id: flow_id });
  }, [flow_id]);

  const updatingStatus = (id) => {
    updateStatus({
      id,
      name: tempStatus,
      flow_id,
      color: hexString,
    })
      .unwrap()
      .then(() => {
        setEditingStatusId(null);
        setTempStatus('');
      });
  };

  const handleEditClick = (item) => {
    setEditingStatusId(item.id);
    setTempStatus(item.name);
    setColorHex(item.color);
  };

  useEffect(() => {
    getDealStatusListQuery({ deal_status_flow_id: flow_id });
  }, [flow_id]);

  const statusGroups = [
    { label: 'Default (Only 1 allowed)', type: 'default', emptyText: 'No default statuses' },
    { label: 'Active', type: 'active', emptyText: 'No active statuses' },
    { label: 'Canceled', type: 'canceled', emptyText: 'No canceled statuses' },
    { label: 'Done', type: 'done', emptyText: 'No completed statuses' },
  ];

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (
      !destination ||
      (source.droppableId === destination.droppableId && source.index === destination.index)
    ) {
      return;
    }

    const draggedStatusId = result.draggableId;
    const newGroup = destination.droppableId;

    const draggedStatus = data.data?.find((status) => status.id.toString() === draggedStatusId);
    if (draggedStatus) {
      updateStatus({
        id: draggedStatusId,
        name: draggedStatus.name,
        flow_id,
        color: draggedStatus.color,
        group: newGroup,
      })
        .unwrap()
        .then(() => {
          getDealStatusListQuery({ deal_status_flow_id: flow_id });
        });
    }
  };

  const unmatchedStatuses =
    data.data?.filter((status) => !statusGroups.some((group) => group.type === status.group)) || [];

  const getStatusCard = ({ status, provided }) => (
    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      <div>
        <List className='statusList'>
          <List.Item
            className='bg-white rounded-md'
            actions={[
              editingStatusId === status.id ? (
                <Flex align='center' gap='small'>
                  <Button
                    key='list-loadmore-save'
                    type='text'
                    onClick={() => updatingStatus(status.id)}
                    icon={<Icon path={mdiContentSave} className='text-orange' />}
                  />
                  <Button
                    shape='circle'
                    type='text'
                    onClick={() => setEditingStatusId(null)}
                    icon={<Icon path={mdiClose} size={1} color={Ninja.colors.light} />}
                  />
                </Flex>
              ) : (
                <Button
                  shape='circle'
                  key='list-loadmore-edit'
                  type='text'
                  onClick={() => handleEditClick(status)}
                  icon={<Icon path={mdiPencil} color={Ninja.colors.light} />}
                />
              ),
              <Button
                shape='circle'
                key='list-loadmore-delete'
                type='text'
                onClick={() => deleteStatus({ deal_status_id: status.id })}
                icon={<Icon path={mdiTrashCanOutline} color={Ninja.colors.light} />}
              />,
            ]}
          >
            <Skeleton avatar title={false} loading={deleteLoading || updateLoading} active>
              <List.Item.Meta
                avatar={
                  <Flex align='center' gap='small'>
                    <Icon path={mdiDrag} size={1.1} />
                    <ColorPicker
                      size='small'
                      value={editingStatusId === status.id ? colorHex : status.color}
                      disabled={editingStatusId !== status.id}
                      format={formatHex}
                      onChange={setColorHex}
                      onFormatChange={setFormatHex}
                      destroyTooltipOnHide={true}
                    />
                  </Flex>
                }
                title={
                  <Typography.Title
                    style={{ color: status.color }}
                    level={5}
                    className='!m-0'
                    editable={
                      editingStatusId === status.id && {
                        icon: null,
                        onChange: (newTitle) => setTempStatus(newTitle),
                        enterIcon: null,
                        editing: true,
                        autoSize: { minRows: 1 },
                      }
                    }
                  >
                    {editingStatusId === status.id ? tempStatus : status.name}
                  </Typography.Title>
                }
              />
            </Skeleton>
          </List.Item>
        </List>
      </div>
    </div>
  );

  return (
    <div className='grid gap-6'>
      <div className='bg-background-light rounded-md p-4 grid gap-4'>
        <DragDropContext onDragEnd={onDragEnd}>
          {statusGroups.map((group) => (
            <div key={group.type}>
              <Typography.Title level={4}>{group.label}</Typography.Title>
              <Droppable droppableId={group.type}>
                {(provided) => {
                  const statuses = data.data?.filter((s) => s.group === group.type) || [];
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {statuses.length ? (
                        statuses.map((status, index) => (
                          <Draggable
                            key={status.id}
                            draggableId={status.id.toString()}
                            index={index}
                          >
                            {(provided, snapshot) =>
                              getStatusCard({
                                status: status,
                                provided: provided,
                                snapshot: snapshot,
                              })
                            }
                          </Draggable>
                        ))
                      ) : (
                        <Typography.Text type='secondary' className='text-xs'>
                          {group.emptyText}
                        </Typography.Text>
                      )}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          ))}
          {unmatchedStatuses.length > 0 && (
            <div>
              <Divider />
              <Typography.Title level={4} className='mt-4'>Available Statuses</Typography.Title>
              <div className='bg-white rounded-lg my-2 px-4 max-h-60 overflow-x-auto'>
                <Droppable droppableId='unmatched'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {unmatchedStatuses.map((status, index) => (
                        <Draggable key={status.id} draggableId={status.id.toString()} index={index}>
                          {(provided, snapshot) =>
                            getStatusCard({
                              status: status,
                              provided: provided,
                              snapshot: snapshot,
                            })
                          }
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          )}
        </DragDropContext>
      </div>
      {addStatus ? (
        <CreateStatus flow_id={flow_id} setAddStatus={setAddStatus} />
      ) : (
        <Button
          size='medium'
          className='w-fit m-auto'
          onClick={() => setAddStatus(true)}
          icon={<Icon path={mdiPlus} />}
        >
          Create Status
        </Button>
      )}
    </div>
  );
};

export default DealStatuses;
