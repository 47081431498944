import { Button, Result } from 'antd';
import useTiktokConnectFlow from 'modules/apps/apps/TiktokApp/contexts/TiktokAppConnectionFlowContextProvider';
import TiktokAppDrawer from 'modules/apps/apps/TiktokApp/drawers/TiktokAppDrawer';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';
import { useGetAccountsByBatchQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const TiktokAppConnectionCompleteStep = (props) => {
  const flow = useTiktokConnectFlow();
  const { data } = useGetAccountsByBatchQuery({
    batch: flow.connectionBatch,
  });

  const { close, open } = TiktokAppDrawer.useDrawer();

  const history = useHistory();

  const accounts = data?.data || [];

  return (
    <Result
      status='success'
      title='Successfully Connected Tiktok accounts'
      subTitle={`You have successfully connected ${accounts.length} accounts. It may take couple minutes to sync all data`}
      extra={[
        <Button
          type='primary'
          onClick={() => {
            close();
            history.push(P.DASHBOARD.INDEX);
          }}
          key='dashboard'
        >
          Go To Dashboard
        </Button>,
        <Button
          key='connect'
          onClick={() => {
            close();
            setTimeout(() => {
              open();
            }, 300);
          }}
        >
          Connect More Accounts
        </Button>,
      ]}
    />
  );
};

TiktokAppConnectionCompleteStep.propTypes = {};

export default TiktokAppConnectionCompleteStep;
