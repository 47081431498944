import React, { useEffect, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Form, Input, Button, Select } from 'antd';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { snackbar } from 'components/notifications/notifications';
import useQueryParams from 'hooks/useQueryParams';
import { useCreateDealMutation } from '../api/crm.api';
import Currency from 'components/Currency';
import NinjaUrlSelect from 'components/Selects/NinjaUrlSelect';
import Icon from '@mdi/react';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { mdiPlus } from '@mdi/js';
import ninjaApi from 'store/redux/apis';
import { StatusSelectFormatter } from 'components/tables/NinjaTable/formatters';
import { CreateContactDrawer } from 'modules/CRMModule/drawers';
import CrmContactSelector from '../pages/CRMView/Component/CrmContactSelector';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const CreateDealDrawer = (props) => {
  const { removeParams, searchParams } = useQueryParams();
  const { contact_id, contact_email } = searchParams;
  const [createDeal, { isLoading }] = useCreateDealMutation();
  const { close: closeCreateDealDrawer } = CreateDealDrawer.useDrawer();
  const [getDealStatusListQuery] = ninjaApi.useLazyDealStatusListQuery();
  const [options, setOptions] = useState([]);
  const { workspace } = useWorkspace();

  const { open: openContactsDrawer } = CreateContactDrawer.useDrawer();

  const handleCreateDeal = (values) => {
    createDeal(values)
      .unwrap()
      .then(() => {
        closeCreateDealDrawer();
        removeParams(['create_deal', 'contact_id', 'contact_email']);
        snackbar.success('Deal created successfully');
      });
  };

  const form = useFormik({
    initialValues: {
      name: '',
      crm_contact_id: '',
      contact_email: [],
      value: '',
      profit: '',
      currency: workspace.currency || 'USD',
      url_id: null,
      status_id: null,
    },
    onSubmit: handleCreateDeal,
    validationSchema: validationSchema,
    validateOnChange: false,
  });

  useEffect(() => {
    if (contact_id && contact_email) {
      form.setFieldValue('crm_contact_id', contact_id);
      form.setFieldValue('contact_email', contact_email);
    }
  }, [contact_id, contact_email]);

  const handleSource = (val) => {
    form.setFieldValue('status_id', null);
    getDealStatusListQuery()
      .unwrap()
      .then((data) => setOptions(data.data));
  };

  return (
    <AutomaticDrawer
      size='large'
      title='Create Deal'
      {...props}
      onClose={() => removeParams(['contact_id', 'contact_email'])}
    >
      <Form
        className='flex flex-col gap-2 justify-center'
        layout='vertical'
        onFinish={handleCreateDeal}
      >
        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-4'>
          <Form.Item
            label='Name'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'name')}
          >
            <Input
              onChange={(e) => {
                form.setFieldValue('name', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.name}
              size='large'
              placeholder='Enter Name'
              name='name'
            />
          </Form.Item>
          <Form.Item
            label='Contact'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'crm_contact_id')}
          >
            <CrmContactSelector
              value={form.values.contact_email}
              onChange={(val) => {
                if (val) {
                  form.setFieldValue('crm_contact_id', val);
                  form.setFieldValue('contact_email', val);
                } else {
                  form.setFieldValue('crm_contact_id', '');
                  form.setFieldValue('contact_email', []);
                }
              }}
              onBlur={form.handleBlur}
              size='large'
              name='contact_email'
            />
            <Button
              className='text-xs font-normal mt-1'
              type='text'
              size='small'
              icon={<Icon path={mdiPlus} size={0.7} />}
              onClick={openContactsDrawer}
            >
              Create Contact
            </Button>
          </Form.Item>
          <Form.Item
            label='Value'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'value')}
          >
            <Input
              type='number'
              onChange={(e) => {
                form.setFieldValue('value', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.value}
              size='large'
              placeholder='Enter Value'
              name='value'
              addonAfter={
                <Currency
                  placeholder='Currency'
                  className='w-24'
                  value={form.values.currency}
                  onChange={(value) => {
                    form.setFieldValue('currency', value);
                  }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            label='Profit'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'profit')}
          >
            <Input
              type='number'
              onChange={(e) => {
                form.setFieldValue('profit', e.target.value);
              }}
              onBlur={form.handleBlur}
              value={form.values.profit}
              size='large'
              placeholder='Enter Profit'
              name='profit'
              addonAfter={
                <Currency
                  placeholder='Currency'
                  className='w-24'
                  value={form.values.currency}
                  onChange={(value) => {
                    form.setFieldValue('currency', value);
                  }}
                />
              }
            />
          </Form.Item>
          <Form.Item
            label='Ad Name'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'url_id')}
          >
            <NinjaUrlSelect
              mode='tag'
              value={form.values.url_id || []}
              size='large'
              placeholder='Choose Ad'
              name='url_id'
              onChange={(val) => {
                form.setFieldValue('url_id', val);
              }}
              onBlur={form.handleBlur}
            />
          </Form.Item>

          <Form.Item
            label='Status'
            hasFeedback
            {...formikFieldToAntProps(form.errors, form.touched, 'status_id')}
          >
            <StatusSelectFormatter
              value={form.values.status_id}
              onChange={(value) => form.setFieldValue('status_id', value)}
              size='large'
              placeholder='Select Status'
              data={options}
            />
          </Form.Item>
        </div>
        <div className='flex-center'>
          <Button onClick={form.handleSubmit} size='large' type='primary' loading={isLoading}>
            Create
          </Button>
        </div>
      </Form>
    </AutomaticDrawer>
  );
};

CreateDealDrawer.requiredParams = {
  create_deal: true,
};

CreateDealDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ create_deal: 1 });
  const close = () => removeParams(['create_deal']);

  return {
    open,
    close,
  };
};

export default CreateDealDrawer;

const validationSchema = yup.object({
  name: yup.string('Enter Name').required('Name is required'),
  crm_contact_id: yup.string('Choose Contact').required('Contact is required'),
  value: yup
    .number()
    .required('Value is required')
    .test(
      'currency-required-for-value',
      'Currency is required when value is set',
      function (value) {
        const { currency } = this.parent;
        return !value || !!currency;
      }
    ),
  profit: yup
    .number()
    .required('Profit is required')
    .test(
      'currency-required-for-profit',
      'Currency is required when profit is set',
      function (profit) {
        const { currency } = this.parent;
        return !profit || !!currency;
      }
    ),
  currency: yup.string('Select currency').required('Currency is required'),
  url_id: yup.mixed().required('URL is required'),
});
