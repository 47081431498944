import ninjaApi from 'store/redux/apis';

export const api = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: [],
  endpoints: (builder) => ({
    userJourney: builder.mutation({
      query: (body) => ({
        url: '/reporting/collect/user-journey',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUserJourneyMutation } = ninjaApi;
