import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar as SingleDate } from 'react-date-range';
import { Popover, InputNumber } from 'antd';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiCalendarMonth } from '@mdi/js';

const DatePicker = (props) => {
  const { formatter = 'yyyy-MM-dd', placeholder = 'Date', onChange, value, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(value ? new Date(value) : new Date());

  useEffect(() => {
    if (value) {
      setDate(new Date(value));
    }
  }, [value]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const onChangeDate = (item) => {
    const date = Date.format(item, formatter);
    setDate(item);
    setOpen(false);

    onChange?.(date);
  };

  return (
    <Popover
      placement='bottomLeft'
      open={open}
      onOpenChange={handleOpenChange}
      trigger='click'
      content={<SingleDate date={date} onChange={onChangeDate} />}
    >
      <InputNumber
        className='w-full h-full'
        suffix={<Icon className='text-orange' path={mdiCalendarMonth} />}
        placeholder={placeholder}
        size='small'
        readOnly
        onClick={() => setOpen(true)}
        value={value ? Date.format(date, formatter) : null}
        {...rest}
      />
    </Popover>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  formatter: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  today: PropTypes.bool,
};

export default DatePicker;
