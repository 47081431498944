import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import useTiktokConnectFlow from 'modules/apps/apps/TiktokApp/contexts/TiktokAppConnectionFlowContextProvider';

const ConnectTiktokStep = (props) => {
  const { onError } = props;
  const flow = useTiktokConnectFlow();

  const connectTiktok = async () => {
    flow
      .doLogin()
      .then(() => {
        console.log('now');
        flow.changeStep('choose_accounts');
      })
      .catch((err) => {
        onError && onError(err);
      });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectTiktok}
        type='dashed'
        size='large'
        loading={flow.loading}
        icon={<NetworkIcon network='tiktok' />}
      >
        {flow.loading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your Tiktok Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectTiktokStep.propTypes = {};

export default ConnectTiktokStep;
