import React, { useState } from 'react';
import { Checkbox, Popconfirm, Tooltip } from 'antd';
import {
  useConnectAccountMutation,
  useDisconnectAccountMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';
import { snackbar } from 'components/notifications/notifications';

const AccountActivationCheckbox = ({ account }) => {
  const [connectQuery, { isLoading: isConnectLoading }] = useConnectAccountMutation();
  const [disconnectQuery, { isLoading: isDisconnectLoading }] = useDisconnectAccountMutation();
  const [open, setOpen] = useState(false);

  const isLoading = isConnectLoading || isDisconnectLoading;

  const showPopconfirm = () => {
    setOpen(true);
  };

  const connectAccount = (checked) => {
    connectQuery({
      batch: account.batch,
      account_id: account.id,
      action: checked ? 'connect' : 'disconnect',
    })
      .unwrap()
      .then((res) => {
        snackbar.success(`Account "${account.name}" successfully connected`);
      })
      .catch((err) => {
        snackbar.error('Error while connecting account');
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const disconnectAccount = (checked) => {
    disconnectQuery({
      batch: account.batch,
      account_id: account.id,
      action: checked ? 'connect' : 'disconnect',
    })
      .unwrap()
      .then((res) => {
        snackbar.success(`Account "${account.name}" successfully disconnected`);
      })
      .catch((err) => {
        snackbar.error('Error while disconnecting account');
      })
      .finally(() => {
        setOpen(false);
      });
  };

  if (account.connected) {
    return (
      <Popconfirm
        placement='top'
        title='Are you sure you want to disconnect account from MyDataNinja?'
        description='Account data will be discarded'
        okText='Yes'
        open={open}
        onCancel={() => setOpen(false)}
        cancelText='No'
        okButtonProps={{ loading: isLoading }}
        onConfirm={() => disconnectAccount(false)}
      >
        <Checkbox onClick={showPopconfirm} loading={isLoading} checked={account.connected} />
      </Popconfirm>
    );
  }

  return (
    <Tooltip title='Check to connect to MyDataNinja'>
      <Checkbox loading={isLoading} checked={account.connected} onChange={connectAccount} />
    </Tooltip>
  );
};

AccountActivationCheckbox.propTypes = {};

export default AccountActivationCheckbox;
