import React from 'react';
import useGoogleAdsConnectFlow from 'modules/apps/apps/GoogleAdsApp/contexts/GoogleAdsAppConnectionFlowContextProvider';
import { useGetAccountsByBatchQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import { Button, Table } from 'antd';
import NetworkAccountFormatter from 'components/table-formatters/network-account';
import AccountActivationCheckbox from 'modules/AccountsModule/components/AccountActivationCheckbox';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight, mdiPageNext, mdiPagePrevious, mdiPlus } from '@mdi/js';

const columns = [
  {
    title: '',
    width: 50,
    key: 'status',
    render: (_, account) => <AccountActivationCheckbox account={account} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
    key: 'name',
    render: (_, account) => <NetworkAccountFormatter copy={false} row={account} />,
  },
  {
    title: 'Account ID',
    dataIndex: 'account_id',
    key: 'account_Id',
  },
  {
    title: 'Timezone',
    dataIndex: 'timezone',
    key: 'timezone',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
];

const ActivateGoogleAdsAccountsStep = (props) => {
  const flow = useGoogleAdsConnectFlow();
  const { data } = useGetAccountsByBatchQuery({
    batch: flow.connectionBatch,
  });

  const accounts = data?.data || [];

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <Table pagination={false} size='small' columns={columns} dataSource={accounts} />
      </div>
      <div className='w-full flex justify-between'>
        <Button
          type='dashed'
          icon={<Icon path={mdiArrowLeft} />}
          onClick={() => flow.changeStep('connect')}
        >
          Previous
        </Button>
        <Button
          type='primary'
          iconPosition='end'
          icon={<Icon path={mdiArrowRight} />}
          onClick={() => flow.changeStep('set_up')}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

ActivateGoogleAdsAccountsStep.propTypes = {};

export default ActivateGoogleAdsAccountsStep;
