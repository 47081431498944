import Module from 'modules/Module';
import P from 'router/paths';
import { ConfirmInvitationPage, ConfirmCrmInvitationPage } from './pages/ConfirmInvitationPage';
import BookDemoPage from 'modules/GuestModule/pages/BookDemoPage';

const GuestModule = () => {
  const module = new Module({
    routes: [
      { path: P.GUEST.CONFIRM_INVITE, component: ConfirmInvitationPage },
      { path: P.GUEST.BOOK_A_DEMO, component: BookDemoPage },
      { path: P.GUEST.CONFIRM_CRM_INVITATION, component: ConfirmCrmInvitationPage },
    ],
  });

  return module.render();
};

export default GuestModule;
