import { googleClientId, googleApiKey } from '@ninja/consts';

const initGoogleSdk = () => {
  return new Promise((resolve) => {
    if (window.gapi?.auth2) {
      resolve();
      return;
    }
    window.handleGoogleApiLoad = () => {
      window.gapi.load('auth2', function () {
        console.log('Google SDK Loaded');
        console.log(window.gapi?.auth2);
        window.gapi.auth2.init({
          apiKey: googleApiKey,
          clientId: googleClientId,
        });
        resolve();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=handleGoogleApiLoad';
      // js.setAttribute('onload', 'this.onload=function(){};handleGoogleApiLoad()');
      // js.setAttribute('onreadystatechange', "if (this.readyState === 'complete') this.onload()");
      js.async = true;
      js.defer = true;
      console.log(js);
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-sdk');
  });
};

export default initGoogleSdk;
