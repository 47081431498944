import { Button, message, notification } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { socketEvents } from 'utils/socket/socket-events';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

/**
 * Default notification duration in seconds
 */
const DEFAULT_DURATION = 3;

const SocketNotifications = () => {
  const [messages, messagesContextHolder] = message.useMessage();
  const [notifications, notificationsContextHolder] = notification.useNotification();
  const history = useHistory();

  const snackbarReceived = (data = {}) => {
    // If it wants to hide the message
    if (data.hide) {
      hideNotification(data, data.duration);
    }

    // If the type is not defined, then return
    if (typeof messages[data.type] !== 'function') return;

    let title = data.title;

    if (data.buttons?.length > 0) {
      title = (
        <div className='flex items-center justify-between gap-4'>
          <span>{data.title}</span>
          {generateNotificationButtons(data, {
            defaultProps: { size: 'small' },
          })}
        </div>
      );
    }

    const props = {
      key: data.key,
      className: 'top-[64px]',
      content: title,
      type: data.type,
      duration: data.duration ? data.duration : data.type === 'loading' ? 60 : DEFAULT_DURATION,
    };
    data.icon && (props.icon = <img src={data.icon} className='w-4 h-4' />);

    messages.open(props);
  };

  const notificationReceived = (data) => {
    // If it wants to hide the message
    if (data.hide) {
      notifications.destroy(data.key);
      return;
    }
    const props = {};
    // If the type is not defined, then return
    if (data.type === 'loading') {
      data.type = 'open';
      props.icon = <LoadingOutlined />;
    }
    if (typeof notifications[data.type] !== 'function') return;

    props.key = data.key;
    props.message = <div dangerouslySetInnerHTML={{ __html: data.title }} />;
    props.description = <div dangerouslySetInnerHTML={{ __html: data.message }} />;
    props.duration = data.duration;
    props.pauseOnHover = data.pauseOnHover;
    props.showProgress = data.showProgress;
    props.placement = data.placement;
    props.btn = generateNotificationButtons(data);
    data.icon && (props.icon = <img src={data.icon} className='w-4 h-4' />);

    notifications[data.type](props);
  };

  const hideNotification = (data, duration = 0) => {
    if (duration) {
      setTimeout(() => {
        notifications.destroy(data.key);
      }, duration * 1000);
    } else {
      notifications.destroy(data.key);
    }
  };

  const generateNotificationButtons = (notification, args = {}) => {
    if (!notification?.buttons?.length) return null;
    const buttonClickAction = (button, notification) => {
      switch (button.type) {
        case 'link': {
          if (button.data.to) {
            if (button.data.newtab) {
              window.open(button.data.to, '_blank');
            } else {
              if (button.data.external) {
                window.open(button.data.to, '_self');
              } else {
                history.push(button.data.to);
              }
            }
          }
          break;
        }
        case 'action': {
          if (typeof functions[button.data.action] === 'function') {
            functions[button.data.action](button.data.data, notification);
          } else {
            console.error(`Button action "${button.data.action}" not found`);
          }
          break;
        }
        case 'js': {
          if (typeof button.data.js === 'string') {
            try {
              eval(button.data.js);
            } catch (err) {
              console.error('Error while button js execution: ', err);
              if (!button.data.safe) {
                throw err;
              }
            }
          }
          break;
        }
        case 'close': {
          hideNotification(notification, button.data.duration);
          break;
        }
      }
    };

    return (
      <div className='flex gap-2 flex-row items-center justify-end flex-wrap'>
        {notification.buttons.map((button, index) => {
          return (
            <Button
              key={index}
              onClick={() => {
                buttonClickAction(button, notification);
              }}
              {...button.props}
              {...args.defaultProps}
            >
              {button.text}
            </Button>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    socketEvents.one('global.snackbar', snackbarReceived);
    socketEvents.one('global.notification', notificationReceived);
  }, []);

  return (
    <Fragment>
      {messagesContextHolder}
      {notificationsContextHolder}
    </Fragment>
  );
};

const functions = {
  test: (data, notification) => {
    alert('test');
    console.log(data);
    console.log(notification);
  },
};

export default SocketNotifications;
