import ninjaApi from 'store/redux/apis/ninja.api';

export const BingAdsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['NetworkAccount'],
  endpoints: (build) => ({
    /* Login facebook Account */
    connectBingAds: build.mutation({
      query: (data) => ({
        url: '/apps/bing-ads/auth/connect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
  }),
});

export const { useConnectBingAdsMutation } = BingAdsApi;
