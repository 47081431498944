import React, { useEffect } from 'react';
import { Button, Divider, Form, Input, Skeleton, Switch } from 'antd';
import PropTypes from 'prop-types';
import {
  useGetNetworkAccountQuery,
  useUpdateNetworkAccountMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountTrackingManualSettingsForm = (props) => {
  const { accountID } = props;
  const [form] = Form.useForm();
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({ id: parseInt(accountID) });
  const [updateAccountQuery, { isLoading: isUpdating }] = useUpdateNetworkAccountMutation();
  const formInitialValues = {
    id: '',
    tracking_url_parameters: '',
    final_url_suffix: '',
  };

  const onSuccess = (data) => {
    form.resetFields();
    props.onSuccess?.(data);
  };

  const submitForm = (data) => {
    return updateAccountQuery(data).unwrap().then(onSuccess);
  };

  useEffect(() => {
    if (!account?.id) return;
    form.setFieldsValue(account);
  }, [account]);

  return (
    <Skeleton loading={isFetching}>
      <Form
        form={form}
        disabled={isFetching || isUpdating}
        name='layout-multiple-horizontal'
        layout='vertical'
        initialValues={formInitialValues}
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item layout='vertical' name='id' hidden />
        <Form.Item
          layout='vertical'
          label='Tracking URL Parameters'
          name='tracking_url_parameters'
          rules={[{ required: true }]}
          extra='Learn more about tracking parameters'
        >
          <Input />
        </Form.Item>
        <Form.Item
          layout='vertical'
          label='Final URL Suffix'
          name='final_url_suffix'
          tooltip={{
            title: 'How tracking works',
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item className='flex-center pt-4'>
          <Button loading={isFetching} type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Skeleton>
  );
};

NetworkAccountTrackingManualSettingsForm.propTypes = {
  accountID: PropTypes.number.isRequired,
};

export default NetworkAccountTrackingManualSettingsForm;
