import { Card, Skeleton } from 'antd';
import ComparableStatistic from 'components/Statistic/ComparableStatistic';
import { addDays } from 'date-fns';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountStatisticsCards = (props) => {
  const { accountId } = props;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({
    id: accountId,
    with_2_day_report: true,
  });

  const reports = useMemo(() => {
    if (!account.reports?.length) return {};
    const report = {};

    const yesterday = Date.format(addDays(new Date(), -1));
    const today = Date.format(new Date());

    report.yesterday = account.reports.find((report) => report.date === yesterday);
    report.today = account.reports.find((report) => report.date === today);

    return report;
  }, [account]);

  return (
    <Skeleton loading={isFetching} active>
      <div className='grid grid-cols-3 gap-2'>
        <Card bordered={false} className='col-span-3 md:col-span-1'>
          <ComparableStatistic
            title='Spent'
            currency
            current={reports.today?.spent}
            compare={reports.yesterday?.spent}
          />
        </Card>
        <Card bordered={false} className='col-span-2 md:col-span-1'>
          <ComparableStatistic
            title='Clicks'
            current={reports.today?.clicks}
            compare={reports.yesterday?.clicks}
          />
        </Card>
        <Card bordered={false} className='col-span-2 md:col-span-1'>
          <ComparableStatistic
            title='Impressions'
            current={reports.today?.impressions}
            compare={reports.yesterday?.impressions}
          />
        </Card>
      </div>
    </Skeleton>
  );
};

export default NetworkAccountStatisticsCards;
