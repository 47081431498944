import '@ninja';
import rootRoutes from 'router/rootRoutes';
import 'react-data-grid/lib/styles.css';
import { renderRoutes } from 'router/renderRoutes';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { ninjaEvents } from 'tracking/ninjaEvents';
import useAuth from 'utils/auth/useAuth';
import useWorkspace from 'store/redux/hooks/useWorkspace';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// import storage from '@ninja/storage';

// window.Pusher = Pusher;

// window.Echo = new Echo({
//   broadcaster: 'reverb',
//   key: 'mydataninja-socket-app-key',
//   wsHost: 'ws.mydataninja.com',
//   authEndpoint: 'https://api.mydataninja.com/api/auth/broadcasting/',
//   wsPort: '8080',
//   auth: {
//     headers: {
//       Authorization: `Bearer ${storage.get('dntoken')}`,
//     },
//   },
//   wssPort: '443',
//   forceTLS: true,
//   connector: 'pusher',
//   enabledTransports: ['ws', 'wss'],
// });
// window.Echo.connector.options.authEndpoint = `https://api.mydataninja.com/broadcasting/auth`;

const App = () => {
  useBootstrapUser();
  const history = useHistory();

  const { user } = useAuth();
  const { workspace } = useWorkspace();

  useEffect(() => {
    ninjaEvents.emit('app_initialized');

    history.listen(() => {
      ninjaEvents.emit('pageview', { location, user, workspace });
    });
  }, []);

  return renderRoutes(rootRoutes);
};

export default App;
