import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import ninjaApi from 'store/redux/apis';

const StatusSelectFormatter = (props) => {
  const [getDealStatusListQuery] = ninjaApi.useLazyDealStatusListQuery();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getDealStatusListQuery()
      .unwrap()
      .then((data) => setOptions(data.data));
  }, []);

  return (
    <Select className='w-full' placeholder='Select Status' {...props}>
      {options.map((option) => (
        <Select.Option key={option.id} value={option.id}>
          <div className='!flex !items-center gap-1 py-1'>
            <div
              className='w-2 h-2 rounded-full'
              style={{
                background: option.color,
                marginRight: 8,
              }}
            />
            <span className='text-sm font-normal' style={{ color: option.color || '#000000' }}>
              {option.name}
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusSelectFormatter;
