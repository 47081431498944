import {
  mdiViewDashboard,
  mdiChartLineVariant,
  mdiAccountMultiple,
  mdiFileChartOutline,
  mdiCameraIris,
  mdiCurrencyUsdCircleOutline,
  mdiAccountDetailsOutline,
  mdiAccountQuestionOutline,
  mdiBullhornOutline,
  mdiCashUsdOutline,
  mdiAccountReactivateOutline,
  mdiSwapHorizontal,
  mdiViewListOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { useMemo } from 'react';
import P from 'router/paths';
import { useSidebar } from 'store/redux/states/ui/ui.hooks';
import { ConfigProvider, Menu } from 'antd';
import Ninja from '@ninja';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const SidebarMenu = () => {
  const [sidebarOpen] = useSidebar();
  const { workspace } = useWorkspace();

  const items = useMemo(() => {
    const items = [
      {
        type: 'group',
        key: 'statistics',
        label: 'Statistics',
        children: [
          {
            key: P.DASHBOARD.INDEX,
            label: <Link to={P.DASHBOARD.INDEX}>Dashboard</Link>,
            icon: <Icon path={mdiViewDashboard} />,
          },
          {
            key: P.SALES.INDEX,
            label: <Link to={P.SALES.INDEX}>Acquisition</Link>,
            icon: <Icon path={mdiChartLineVariant} />,
          },
          {
            key: P.POSTBACKS.INDEX,
            label: <Link to={P.POSTBACKS.INDEX}>Retention</Link>,
            icon: <Icon path={mdiAccountMultiple} />,
          },
          {
            key: P.REPORTING.INDEX,
            label: <Link to={P.REPORTING.INDEX}>Reporting</Link>,
            icon: <Icon path={mdiFileChartOutline} />,
          },
          {
            key: P.REPORTING.CREATIVE,
            label: <Link to={P.REPORTING.CREATIVE}>Creative Analisys</Link>,
            icon: <Icon path={mdiCameraIris} />,
          },
        ],
      },
      {
        type: 'group',
        key: 'crm',
        label: 'CRM',
        children: [
          {
            key: P.CRM.CONTACTS,
            label: <Link to={P.CRM.CONTACTS}>Contacts</Link>,
            icon: <Icon path={mdiViewListOutline} />,
          },
          {
            key: P.CRM.DEALS,
            label: <Link to={P.CRM.DEALS}>Deals</Link>,
            icon: <Icon path={mdiCurrencyUsdCircleOutline} />,
          },
          {
            key: P.CRM.CONTACTS,
            label: <Link to={P.CRM.CONTACTS}>Leads</Link>,
            icon: <Icon path={mdiAccountDetailsOutline} />,
          },
        ],
      },
      {
        type: 'group',
        key: 'setup',
        label: 'Setup',
        children: [
          {
            key: P.SETUP.MARKETING,
            label: <Link to={P.SETUP.MARKETING}>Marketing Investments</Link>,
            icon: <Icon path={mdiBullhornOutline} />,
          },
          {
            key: P.SETUP.REVENUE,
            label: <Link to={P.SETUP.REVENUE}>Revenue Sources</Link>,
            icon: <Icon path={mdiCashUsdOutline} />,
          },
          {
            key: P.DOMAIN.INDEX,
            label: <Link to={P.DOMAIN.INDEX}>Tracking</Link>,
            icon: <Icon path={mdiAccountReactivateOutline} />,
          },
          {
            key: P.CONVERSIONS.INDEX,
            label: <Link to={P.CONVERSIONS.INDEX}>Conversions</Link>,
            icon: <Icon path={mdiSwapHorizontal} />,
          },
        ],
      },
    ];
    return items;
  }, [workspace]);

  const onClick = (e) => {
    console.log('click ', e);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemBg: Ninja.colors.bgColorDark,
            darkItemHoverBg: Ninja.colors.orange,
            darkItemColor: 'white',
            iconSize: '20px',
          },
        },
      }}
    >
      <Menu
        className='w-full'
        onClick={onClick}
        inlineCollapsed={!sidebarOpen}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode='vertical'
        theme='dark'
        selectedKeys={[window.location.pathname]}
        items={items}
      />
    </ConfigProvider>
  );
};

export default SidebarMenu;
